import { FC } from 'react'
import { ActivityListCardItem_CurrentUserFragment } from 'generated/graphql'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import PopularRecruitLoadingDesktop from '@app.feature/ad/desktop/PopularRecruit/loading/PopularRecruitLoading.desktop'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import PopularRecruitDesktop from './PopularRecruit.desktop'

interface IFProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  isScreenQueryLoading: boolean
}

const PopularRecruitStatusDesktop: FC<IFProps> = ({
  currentUser,
  isScreenQueryLoading,
}) => {
  const { ads, loading, error } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.RECRUIT.PC,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (isScreenQueryLoading || loading || error) {
    return <PopularRecruitLoadingDesktop />
  }

  if (!ads?.length) {
    return null
  }

  return <PopularRecruitDesktop ads={ads} currentUser={currentUser} />
}

export default PopularRecruitStatusDesktop
