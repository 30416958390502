import { FC, useRef } from 'react'
import styled from 'styled-components'
import { ArrowLeft, ArrowRight } from 'svgIcons'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { UseGetAdsByPlacementCode_AdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useSwiperAdViewportTracking from '@app.feature/ad/hooks/useSwiperAdViewportTracking'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import MainBannerItemDesktop from './item/MainBannerItem.desktop'

interface IFProps {
  ads: Array<UseGetAdsByPlacementCode_AdFragment>
}

const MainBannerListDesktop: FC<IFProps> = ({ ads }) => {
  const swiperRef = useRef<SwiperClass | null>(null)

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const trackImpression = (index: number) => {
    const currentAd = ads[index]
    if (currentAd?.campaign?.id) {
      pushGTMCampaignId({
        eventName: GTM_EVENT.MAIN.PC.IMPRESSION_EVENT,
        campaignId: currentAd.campaign.id,
      })
    }
  }

  const { containerRef, isVisible } = useSwiperAdViewportTracking({
    swiperRef: swiperRef,
    onVisible: trackImpression,
  })

  const handleRealIndexChange = (swiper: SwiperClass) => {
    if (isVisible) {
      trackImpression(swiper.realIndex)
    }
  }

  return (
    <StyledWrapper ref={containerRef}>
      <>
        <button className="swiper-button-left" onClick={handlePrevClick}>
          <ArrowLeft className="arrow-icon" />
        </button>
        <button className="swiper-button-right" onClick={handleNextClick}>
          <ArrowRight className="arrow-icon" />
        </button>
      </>
      <Swiper
        key="main-banner-desktop-swiper"
        slidesPerView={1}
        loop
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        modules={[Pagination, Autoplay, Navigation]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
        onRealIndexChange={handleRealIndexChange}
      >
        {ads?.map((ad, index) => (
          <SwiperSlide key={`main-banner-desktop-placement-${ad.id}`}>
            <MainBannerItemDesktop
              ad={ad}
              activeSlide={index + 1}
              totalSlides={ads?.length}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledWrapper>
  )
}

export default MainBannerListDesktop

const StyledWrapper = styled.div`
  position: relative;
  width: 345px;
  height: 500px;

  .swiper-button-left,
  .swiper-button-right {
    position: absolute;
    z-index: 2;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border: none;
    cursor: pointer;
  }

  .swiper-button-left {
    top: 200px;
    left: -15px;
  }
  .swiper-button-right {
    top: 200px;
    right: -15px;
  }

  .arrow-icon {
    width: 10px;
    height: 10px;
  }

  .custom-pagination {
    position: absolute;
    z-index: 1;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    right: 15px;
    bottom: 15px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
  }
`
