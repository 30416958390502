import styled, { css } from 'styled-components'
import FieldBirthday from '@app.feature/signup/signup/components/signupInfo/FieldBirthday'
import FieldSchool from '@app.feature/signup/signup/components/signupInfo/FieldSchool'

const UserInfoModalInfo = () => {
  return (
    <StyledWrapper>
      <FieldSchool />
      <FieldBirthday />
    </StyledWrapper>
  )
}

export default UserInfoModalInfo

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    margin-top: ${theme.isMobile ? '28px' : '32px'};
    gap: ${theme.isMobile ? '28px' : '31px'};
  `}
`
