import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  ActivityListCardItem_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListCardItem from 'components/ActivityListCardItem'

interface IFPopularActivityMobileProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
}

const PopularActivityMobile: FC<IFPopularActivityMobileProps> = ({
  currentUser,
  ads,
}) => {
  const processedCampaignIds = useRef<Set<string>>(new Set())

  const handlePushGtmEvent = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.WEEKLY_POPULAR.MOBILE?.CLICK_EVENT || '',
      campaignId: ad.ad?.campaign?.id || '',
    })
  }

  const handleOnClickOutLink = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    handlePushGtmEvent(ad)
    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad?.campaign?.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  useEffect(() => {
    ads?.forEach((ad) => {
      const campaignId = ad.ad?.campaign?.id
      if (campaignId && !processedCampaignIds.current.has(campaignId)) {
        processedCampaignIds.current.add(campaignId)
        pushGTMCampaignId({
          eventName: GTM_EVENT.WEEKLY_POPULAR.MOBILE?.IMPRESSION_EVENT || '',
          campaignId,
        })
      }
    })
  }, [])

  return (
    <StyledWrapper>
      <h2 className="title-text">금주의 인기공고</h2>
      <div className="card-list">
        {ads.map((ad) => {
          return (
            <div
              key={ad.activity.id}
              className="card-item"
              {...(!!ad.ad && {
                'aria-label': 'Advertisement',
                role: 'advertisement',
              })}
            >
              <ActivityListCardItem
                managerBadgeOn={false}
                activity={ad.activity}
                cardTitle={ad.activity?.title}
                currentUser={currentUser}
                cardImageProps={{
                  width: 155,
                  src: getActivityListFromAdsImageUrl({
                    adImage: ad.ad?.image?.url,
                    activity: ad.activity,
                  }),
                  alt: ad.activity?.title || '',
                }}
                {...(ad.ad?.campaign?.landingURL && {
                  linkProps: {
                    onClick: () => handleOnClickOutLink(ad),
                  },
                })}
                onClick={() => handlePushGtmEvent(ad)}
              />
            </div>
          )
        })}
      </div>
    </StyledWrapper>
  )
}

export default PopularActivityMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .loading-wrapper {
    display: flex;
    gap: 10px;
    padding-right: 20px;
    .loading-item {
      width: 100%;
    }
  }
  .title-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding-right: 20px;
    ::-webkit-scrollbar {
      display: none;
    }
    .card-item {
      width: calc(50% - 5px);
      flex-shrink: 0;
    }
  }
`
