import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  ActivityListItem_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListItem from 'components/ActivityListItem'

interface IFProps {
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityListItem_CurrentUserFragment | null
}
const LiveActivityListMobile: FC<IFProps> = ({ ads, currentUser }) => {
  const processedCampaignIds = useRef<Set<string>>(new Set())

  const handleOnClickOutLink = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.LIVE_ACTIVITY.MOBILE?.CLICK_EVENT || '',
      campaignId: ad.ad?.campaign?.id || '',
    })
    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad?.campaign?.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  useEffect(() => {
    ads?.forEach((ad) => {
      const campaignId = ad.ad?.campaign?.id
      if (campaignId && !processedCampaignIds.current.has(campaignId)) {
        processedCampaignIds.current.add(campaignId)
        pushGTMCampaignId({
          eventName: GTM_EVENT.LIVE_ACTIVITY?.MOBILE?.IMPRESSION_EVENT || '',
          campaignId,
        })
      }
    })
  }, [])

  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 활동정보</h2>
      <div>
        {ads?.map((ad) => {
          const isAd = !!ad.ad

          return (
            <div
              key={ad.activity.id}
              className="activity-item"
              {...(!!isAd && {
                'aria-label': 'Advertisement',
                role: 'advertisement',
              })}
              data-is-ad={isAd}
            >
              <ActivityListItem
                activity={ad.activity}
                currentUser={currentUser}
                showActivityType
                showHotIcon={isAd}
                className="activity-item"
                {...(ad.ad?.campaign?.landingURL && {
                  linkProps: {
                    onClick: () => handleOnClickOutLink(ad),
                  },
                })}
              />
            </div>
          )
        })}
      </div>
    </StyledWrapper>
  )
}

export default LiveActivityListMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  background-color: #fff;
  .title-text {
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 10px 20px;
  }
  .activity-item {
    &[data-is-ad='false'] {
      border-bottom: 1px solid #e8e8e8;
    }
    &[data-is-ad='true'] {
      border-bottom: 1px solid #d6f0ff;
      .activity-item {
        background-color: #f2faff;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
`
