import { ActivityListCardItem_ActivityFragment } from 'generated/graphql'
import { ActivityTypeID } from 'constants/enums'

type TGetActivityListFromAdsImageUrlFn = (params: {
  adImage?: string
  activity: ActivityListCardItem_ActivityFragment
}) => string

/**
 * 광고 데이터 이미지 처리 조건에 따라 imageUrl을 리턴합니다.
 *
 * @description
 * 광고 노출 이미지 URL 조건
 * 1. CMS에서 등록한 광고가 있는 경우, 등록한 광고 이미지 사용
 * 2. CMS에서 등록한 광고가 없는 경우 서버에서 응답해주는 activity 사용
 *    - activity type이 RECRUIT일 경우, logoImage URL 사용
 *    - activity type이 RECRUIT가 아닐 경우, thumbnailImage URL 사용
 */
const getActivityListFromAdsImageUrl: TGetActivityListFromAdsImageUrlFn = ({
  adImage,
  activity,
}) => {
  if (adImage) {
    return adImage
  }
  const isRecruit = activity?.type === ActivityTypeID.RECRUIT
  const activityImage = isRecruit
    ? activity?.logoImage?.url
    : activity?.thumbnailImage?.url
  return activityImage ?? ''
}

export default getActivityListFromAdsImageUrl
