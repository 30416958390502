import { useEffect } from 'react'
import { pushGTMCampaignId } from '../modules/gtmEvents'

type TAdGTMEventPayload = {
  eventName: string
  campaignId?: string
}

type TUseAdImpressionTrackerFn = (params: TAdGTMEventPayload) => void

/**
 * 광고 impression 이벤트를 추적하는 커스텀 훅
 * 매 렌더링마다 이벤트를 발생시킵니다.
 */
const useAdImpressionTracker: TUseAdImpressionTrackerFn = ({
  eventName,
  campaignId,
}: TAdGTMEventPayload) => {
  useEffect(() => {
    if (campaignId) {
      pushGTMCampaignId({
        eventName,
        campaignId,
      })
    }
  }, [])
}

export default useAdImpressionTracker
