/**
 * 주어진 배열의 요소들을 무작위로 섞어 새로운 배열을 반환합니다.
 * @template T - 배열 요소의 타입
 * @param {T[]} array - 섞고자 하는 원본 배열
 * @returns {T[]} 무작위로 섞인 새로운 배열
 */
const shuffleList = <T>(array: T[]): T[] => {
  const shuffled = [...array]
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }
  return shuffled
}

export default shuffleList
