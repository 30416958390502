import { memo } from 'react'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetAdsByPlacementCode from '@app.feature/ad/hooks/useGetAdsByPlacementCode'
import MainBannerListMobile from './MainBannerList.mobile'

const MainBannerStatusMobile = () => {
  const { ads, loading, error } = useGetAdsByPlacementCode({
    code: AD_CODE_ID.MAIN.MOBILE!,
    isShuffled: true,
  })

  if (loading || error || !ads?.length) {
    return <StyledWrapper />
  } else {
    return <MainBannerListMobile ads={ads} />
  }
}

export default memo(MainBannerStatusMobile)

const StyledWrapper = styled(Skeleton)`
  width: calc(100% - 30px);
  height: 220px;
  margin: 15px;
`
