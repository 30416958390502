import { memo } from 'react'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetAdsByPlacementCode from '@app.feature/ad/hooks/useGetAdsByPlacementCode'
import HotLineBannerListMobile from './HotLineBannerList.mobile'

const HotLineBannerStatusMobile = () => {
  const { ads, loading, error } = useGetAdsByPlacementCode({
    code: AD_CODE_ID.HOTLINE.MOBILE!,
    isShuffled: true,
  })

  if (loading || error || !ads?.length) {
    return <StyledWrapper />
  } else {
    return <HotLineBannerListMobile ads={ads} />
  }
}

export default memo(HotLineBannerStatusMobile)

const StyledWrapper = styled(Skeleton)`
  width: calc(100% - 30px);
  height: 120px;
  margin: 15px;
`
