import styled from 'styled-components'
import ActivityListItemLoading from 'components/ActivityListItemLoading.mobile'

const LIVE_ACTIVITY_ITEM_PER_VIEW = 8

const LiveActivityLoadingMobile = () => {
  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 활동정보</h2>
      {new Array(LIVE_ACTIVITY_ITEM_PER_VIEW).fill('').map((_, index) => (
        <ActivityListItemLoading key={`live-activity-loading-${index}`} />
      ))}
    </StyledWrapper>
  )
}

export default LiveActivityLoadingMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  background-color: #fff;

  .title-text {
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 10px 20px;
  }
`
