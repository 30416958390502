import { FC, MouseEventHandler, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useAdImpressionTracker from '@app.feature/ad/hooks/useAdImpressionTracker'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ScrapBoxButton from 'components/ActivityListCardItem/ScrapBoxButton'
import { imageLoader, optimizeImageUrl } from 'utils/optimizeImageUrl'
import SBannerHoverCardDesktop from './SBannerHoverCard.desktop'

export const RECOMMEND_S_BANNER_PC_IMAGE_SIZE = {
  width: 528,
  height: 360,
}

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
}

const SBannerDesktop: FC<IFProps> = ({ currentUser, ad }) => {
  const [isDetailVisible, setIsDetailVisible] = useState(false)
  const router = useRouter()
  const isAd = !!ad.ad

  useAdImpressionTracker({
    eventName: GTM_EVENT.RECOMMEND_ACTIVITY_S.PC.IMPRESSION_EVENT,
    campaignId: ad.ad?.campaign?.id || '',
  })

  const handleClickBanner = () => {
    if (!isAd) {
      return
    }
    pushGTMCampaignId({
      eventName: GTM_EVENT.RECOMMEND_ACTIVITY_S.PC.CLICK_EVENT,
      campaignId: ad.ad?.campaign?.id || '',
    })

    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad.campaign.landingURL, '_blank', 'noopener, noreferrer')
    }

    router.push(`/activity/${ad.activity.id}`)
  }

  const handleDetailMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
    setIsDetailVisible(true)
  }

  const handleDetailMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    setIsDetailVisible(false)
  }

  return (
    <StyledWrapper aria-label="Advertisement" role="advertisement">
      <div
        className="image-wrapper"
        onMouseEnter={handleDetailMouseEnter}
        onMouseLeave={handleDetailMouseLeave}
      >
        <Link href={`/activity/${ad?.activity.id}`}>
          <a onClick={handleClickBanner}>
            <Image
              loader={imageLoader}
              className="thumbnail-image"
              key={`s-banner-${ad?.activity?.id}`}
              alt={ad?.activity?.title || 'S배너 이미지'}
              width={RECOMMEND_S_BANNER_PC_IMAGE_SIZE.width}
              height={RECOMMEND_S_BANNER_PC_IMAGE_SIZE.height}
              src={optimizeImageUrl({
                url: getActivityListFromAdsImageUrl({
                  adImage: ad?.ad?.image?.url,
                  activity: ad?.activity,
                }),
                width: RECOMMEND_S_BANNER_PC_IMAGE_SIZE.width,
                height: RECOMMEND_S_BANNER_PC_IMAGE_SIZE.height,
              })}
            />
            {isDetailVisible && <SBannerHoverCardDesktop ad={ad} />}
          </a>
        </Link>
        <ScrapBoxButton
          activity={ad?.activity}
          currentUser={currentUser ?? undefined}
        />
      </div>
    </StyledWrapper>
  )
}

export default SBannerDesktop

const StyledWrapper = styled.aside`
  width: 100%;
  max-width: 552px;
  padding: 0 12px 50px 12px;

  .image-wrapper {
    position: relative;

    .thumbnail-image {
      border-radius: 5px;
      border: 1px solid #e8e8e8 !important;
    }

    .info-card {
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 67px;
      width: 100%;
      padding: 10px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      .info-title {
        font-size: 16px;
        letter-spacing: -0.64px;
        font-weight: bold;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .second-info-text {
        display: flex;
        justify-content: space-between;
        .organization-name {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
`
