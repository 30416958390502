import { FC } from 'react'
import { ActivityScrap_CurrentUserFragment } from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import SBannerDesktop from './SBanner.desktop'

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const SBannerStatusDesktop: FC<IFProps> = ({ currentUser }) => {
  const { ads, loading, error } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.RECOMMEND_ACTIVITY_S.PC,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (loading || error || !ads?.length) {
    return <Skeleton width={528} height={360} />
  }

  const ad = ads?.[0]

  return <SBannerDesktop ad={ad} currentUser={currentUser} />
}

export default SBannerStatusDesktop
