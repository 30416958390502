import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { UseGetAdsByPlacementCode_AdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import { imageLoader, optimizeImageUrl } from 'utils/optimizeImageUrl'

interface IFProps {
  ad: UseGetAdsByPlacementCode_AdFragment
  activeSlide: number
  totalSlides: number
}

const MAIN_BANNER_MOBILE_IMAGE_SIZE = {
  width: 914,
  height: 220,
}

const MainBannerItemMobile: FC<IFProps> = ({
  ad,
  activeSlide,
  totalSlides,
}) => {
  const handleClickOutLink = () => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.MAIN.MOBILE?.CLICK_EVENT || '',
      campaignId: ad.campaign?.id || '',
    })

    if (ad.campaign?.landingURL) {
      window.open(ad.campaign.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  const activityUrl = ad.activity?.id ? `/activity/${ad.activity?.id}` : ''

  const alt = ad?.campaign?.campaignGroup?.name
    ? `${ad?.campaign?.campaignGroup?.name} 메인 배너 이미지`
    : `메인 배너 이미지`

  return (
    <StyledWrapper onClick={handleClickOutLink}>
      <Link href={activityUrl}>
        <a rel="nofollow sponsored">
          <mark className="custom-pagination">{`${activeSlide}/${totalSlides}`}</mark>
          <Image
            layout="responsive"
            className="main-banner-image"
            key={`main-banner-mobile-image-${ad.id}`}
            alt={alt}
            width={MAIN_BANNER_MOBILE_IMAGE_SIZE.width}
            height={MAIN_BANNER_MOBILE_IMAGE_SIZE.height}
            loader={imageLoader}
            src={optimizeImageUrl({
              url: ad.image?.url,
              width: MAIN_BANNER_MOBILE_IMAGE_SIZE.width,
              height: MAIN_BANNER_MOBILE_IMAGE_SIZE.height,
            })}
          />
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default MainBannerItemMobile

const StyledWrapper = styled.div`
  .main-banner-image {
    border-radius: 5px;
  }

  .custom-pagination {
    position: absolute;
    z-index: 1;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    right: 10px !important;
    bottom: 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
  }
`
