import { FC } from 'react'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import RecommendBannerCardImageMobile from './content/RecommendBannerCardImage.mobile'
import RecommendBannerCardInfoMobile from './content/RecommendBannerCardInfo.mobile'

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
  totalSlide: number
  activeSlide: number
}

const RecommendBannerCardMobile: FC<IFProps> = ({
  currentUser,
  ad,
  totalSlide,
  activeSlide,
}) => {
  return (
    <StyledWrapper aria-label="Advertisement" role="advertisement">
      <RecommendBannerCardImageMobile
        currentUser={currentUser}
        ad={ad}
        totalSlide={totalSlide}
        activeSlide={activeSlide}
      />
      <RecommendBannerCardInfoMobile ad={ad} />
    </StyledWrapper>
  )
}

export default RecommendBannerCardMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
