import { forwardRef } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

type TMenuList = {
  label: string
  href: string
}

const MENU_LIST: Array<TMenuList> = [
  {
    label: '대외활동',
    href: '/list/activity',
  },
  {
    label: '공모전',
    href: '/list/contest',
  },
  {
    label: '동아리',
    href: '/list/club',
  },
  {
    label: '채용',
    href: '/list/recruit',
  },
  {
    label: '교육',
    href: '/list/education',
  },
]

const ActivityTypeDropdown = forwardRef<HTMLUListElement>((_, ref) => {
  return (
    <StyledWrapper ref={ref} role="menu">
      {MENU_LIST.map((menu) => (
        <li key={menu.label} role="none">
          <Link href={menu.href}>
            <a className="menu-item" role="menuitem">
              {menu.label}
            </a>
          </Link>
        </li>
      ))}
    </StyledWrapper>
  )
})

export default ActivityTypeDropdown

const StyledWrapper = styled.ul`
  position: absolute;
  top: 30px;
  right: 7px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
  .menu-item {
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 500;
    :hover {
      background-color: #f2f2f2;
    }
  }
`
