import { isValid, parseISO } from 'date-fns'
import * as yup from 'yup'
import { ActivityTypeID } from 'constants/enums'

const userInfoModalSchema = yup.object({
  preferJobCategoryIds: yup.array().of(yup.string()).min(1),

  preferActivityTypes: yup.array().of(yup.number()).min(1),

  preferOrganizationIds: yup
    .array()
    .of(yup.string())
    .nullable()
    .test({
      name: 'is-checked-recruit',
      test: function (value) {
        const { preferActivityTypes } = this.parent
        if (
          preferActivityTypes &&
          preferActivityTypes.includes(ActivityTypeID.RECRUIT)
        ) {
          return (value ?? []).length >= 1
        }
        return true
      },
    }),

  schoolType: yup.string().required(),
  school: yup.object({
    id: yup.string().required(),
    name: yup.string(),
  }),

  birthday: yup
    .string()
    .test({
      name: 'is-only-number',
      test: (birthday) => !birthday || /^[0-9\\.]+$/g.test(birthday),
      message: '생년월일 형식이 올바르지 않습니다',
    })
    .test({
      name: 'is-valid-date',
      test: (birthday) => {
        const isEmpty = !birthday
        if (isEmpty) {
          return true
        }

        const birthdayISO = parseISO(birthday.replaceAll('.', '-'))
        const isFuture = birthdayISO > new Date()
        const isValidated = birthday?.length === 8 && isValid(birthdayISO)

        if (!isFuture && isValidated) {
          return true
        }

        return false
      },
      message: '생년월일 형식이 올바르지 않습니다',
    })
    .required('생년월일을 입력해주세요'),

  subscribeSMS: yup.boolean(),

  phoneNumber: yup.string().required(),
  phoneInput: yup.string().test({
    name: 'phone-input',
    test: function (inputValue) {
      const { phoneNumber } = this.parent
      if (phoneNumber || (inputValue && inputValue.length >= 10)) {
        return true
      }
      return false
    },
    message: '번호를 올바르게 입력해주세요',
  }),
  phoneVerification: yup.string(),
})

export default userInfoModalSchema
