import Image from 'next/image'
import styled, { css } from 'styled-components'
import Portal from '@app.components/Portal'
import CloseIcon from 'svgIcons/Close'

interface IFProps {
  onClickCancel: () => void
  onClickConfirm: () => void
}
const UserInfoPreviewModal = ({ onClickCancel, onClickConfirm }: IFProps) => {
  return (
    <Portal id="signup-preview-modal">
      <StyledWrapper>
        <article className="preview-modal-content">
          <i role="button" onClick={onClickCancel}>
            <CloseIcon width={16} height={16} fill="#333" />
          </i>
          <Image
            src={`/static/images/signup/popup-image.png`}
            width={192}
            height={106}
          />
          <p>
            지금 관심분야와 직무를 입력하고 <br />
            <strong>맞춤형 커리어 정보</strong>를 받아보세요!
          </p>
          <button
            type="button"
            className="button-confirm"
            onClick={onClickConfirm}
          >
            정보 입력하기
          </button>
        </article>
      </StyledWrapper>
    </Portal>
  )
}

export default UserInfoPreviewModal

const StyledWrapper = styled.aside`
  ${({ theme }) => css`
    z-index: 600;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    overflow-y: auto;

    .preview-modal-content {
      width: 340px;
      height: 285px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      background-color: white;
      border-radius: 20px;

      > i {
        position: absolute;
        right: 19px;
        top: 19px;
      }

      & > p {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.56;
        letter-spacing: -0.72px;
        color: ${theme.color.text_1};
        margin-top: 11px;
        margin-bottom: 24px;
        > strong {
          color: ${theme.color.primary_2};
          font-weight: 500;
        }
      }

      & > button {
        width: 100%;
        height: 50px;
        padding: 13px 30px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.64px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 8px;
        cursor: pointer;
        background-color: ${theme.color.primary_2};
      }
    }
  `}
`
