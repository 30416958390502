import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'

const ActivityListItemLoadingMobile = () => {
  return (
    <StyledWrapper data-testid="activity-list-item-loading">
      <div className="thumbnail-container">
        <Skeleton width={60} height={60} />
      </div>
      <div className="content-container">
        <div className="content-inner">
          <div className="organization-container">
            <Skeleton width={100} height={13} />
          </div>
          <div className="title-container">
            <Skeleton height={13} />
          </div>
          <div className="info-container">
            <div className="info-item">
              <Skeleton width={40} height={13} />
            </div>
            <Skeleton width={40} height={13} />
          </div>
        </div>
        <div className="scrap-container">
          <Skeleton width={20} className="scrap-count-skeleton" height={13} />
        </div>
      </div>
    </StyledWrapper>
  )
}

export default ActivityListItemLoadingMobile

const StyledWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 15px 10px 15px 20px;
  display: flex;
  align-items: center;

  .thumbnail-container {
    width: 60px;
    margin-right: 15px;
    flex-shrink: 0;
    align-items: center;
    display: flex;
  }

  .content-container {
    flex-grow: 1;
    display: flex;
    min-width: 0;
    align-items: center;
  }

  .content-inner {
    margin-right: 30px;
    flex-grow: 1;
    display: flex;
    min-width: 0;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .organization-container {
    margin-right: 30px;
    margin-bottom: 4px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .title-container {
    margin-right: 30px;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 4px;
  }

  .info-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-shrink: 0;
  }

  .info-item {
    margin-right: 8px;
  }

  .scrap-container {
    flex-shrink: 0;
  }

  .scrap-count-skeleton {
    margin: auto;
  }
`
