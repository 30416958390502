type TPushGTMEventFn = (params: {
  eventName: string
  eventData: Record<string, any>
}) => void

/**
 * Google Tag Manager 이벤트를 발생시키는 함수
 * window.dataLayer가 존재할 경우 이벤트를 push합니다.
 *
 * @param eventName - GTM에서 트리거로 사용될 이벤트 이름
 * @param eventData - 이벤트와 함께 전송될 추가 데이터 객체
 * @example
 * pushGTMCampaignId({
 *   eventName: 'view_campaign',
 *   {campaign_id : '12345'}
 * })
 */
export const pushGTMEvent: TPushGTMEventFn = ({ eventName, eventData }) => {
  if (window?.dataLayer) {
    window?.dataLayer?.push({
      event: eventName,
      ...eventData,
    })
  }
}

type TPushGTMCampaignIdFn = (params: {
  eventName: string
  campaignId: string
}) => void

/**
 * Campaign ID를 포함한 GTM 이벤트를 발생시키는 함수
 * window.dataLayer가 존재할 경우 이벤트를 push합니다.
 *
 * @param eventName - GTM에서 트리거로 사용될 이벤트 이름
 * @param campaignId - 캠페인 ID
 * @example
 * pushGTMCampaignId({
 *   eventName: 'view_campaign',
 *   campaign_id: '12345'
 * })
 */
export const pushGTMCampaignId: TPushGTMCampaignIdFn = ({
  eventName,
  campaignId,
}) => {
  if (!window?.dataLayer || !campaignId || !eventName) {
    return
  }
  window.dataLayer.push({
    event: eventName,
    campaign_id: campaignId,
  })
}
