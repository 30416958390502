import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import { useGqlPreferredOrganizationsQuery } from 'generated/graphql'
import FormLabel from '@app.feature/signup/signup/desktop/components/FormLabel'
import { SignupFormType } from '@app.feature/signup/signup/types/signupFormType'
import { UserInfoModalType } from '@app.feature/userInfoModal/type/userInfoModalType'
import { ActivityTypeID } from 'constants/enums'
import { ACTIVITY_TYPE_TEXT_ID } from 'constants/typeText'
import ActivityContainer from './components/ActivityContainer'
import CompanyContainer from './components/CompanyContainer'

const FieldActivity = () => {
  const { watch, resetField } = useFormContext<
    SignupFormType | UserInfoModalType
  >()
  const { data, loading, error } = useGqlPreferredOrganizationsQuery()

  const activites = ['recruit', 'activity', 'contest', 'education']
  const isRecruitChecked = (watch('preferActivityTypes') || [])
    .map(Number)
    .includes(ActivityTypeID.RECRUIT)

  const selectedPreferActivityTypes =
    useWatch({ name: 'preferActivityTypes' }) || []

  useEffect(() => {
    if (!isRecruitChecked) {
      resetField('preferOrganizationIds', { defaultValue: [] })
    }
  }, [isRecruitChecked])

  return (
    <StyledWrapper>
      <FormLabel label="관심 분야" canMultiple={true} />
      <div className="field-activity-container">
        {activites.map((name, index) => {
          const id = String(ACTIVITY_TYPE_TEXT_ID[name])

          return (
            <ActivityContainer
              key={index}
              name={name}
              checked={selectedPreferActivityTypes?.includes(id) || false}
            />
          )
        })}
      </div>
      {isRecruitChecked && (
        <>
          <FormLabel label="관심 기업" canMultiple={true} />
          <div>
            {data &&
              !loading &&
              !error &&
              data.preferredOrganizations.map((company) => {
                return <CompanyContainer key={company.id} company={company} />
              })}
          </div>
        </>
      )}
    </StyledWrapper>
  )
}
export default FieldActivity

const StyledWrapper = styled.article`
  width: 100%;
  display: grid;
  gap: 16px;
  .field-activity-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 102px;
    gap: 10px;
  }
`
