import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import MagnifyingGlassIcon from 'svgIcons/MagnifyingGlass'
import { isSpamSearchInput } from 'utils/spamKeywordsUtils'

type TForm = {
  search: string
}

const SearchBarMobile: FC = () => {
  const router = useRouter()
  const { register, handleSubmit } = useForm<TForm>()

  const onSubmit = (data: TForm) => {
    if (!data.search) return
    if (isSpamSearchInput([data.search])) {
      return
    }

    router.push({
      pathname: '/search',
      query: {
        ...router.query,
        q: data.search,
        p: 1,
      },
    })
  }

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <button className="search-icon-btn">
          <MagnifyingGlassIcon />
        </button>
        <input
          id="header-search"
          className="search-input"
          type="search"
          placeholder="대외활동, 인턴, 채용 정보 검색"
          defaultValue={router.query.q || ''}
          {...register('search')}
        />
      </form>
    </StyledWrapper>
  )
}

export default SearchBarMobile

const StyledWrapper = styled.div`
  padding: 10px 14px;

  .search-input {
    background-color: #fff;
  }
  .form {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    padding: 0 7px;
    input {
      width: 100%;
      font-size: 16px;
      border: none;
      outline: none;
    }
    .search-icon-btn {
      display: flex;
      border: none;
      background-color: transparent;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`
