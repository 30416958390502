import { FC } from 'react'
import styled from 'styled-components'
import { ActivityScrap_CurrentUserFragment } from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import ABannerDesktop from './ABanner.desktop'

export const RECOMMEND_A_BANNER_ITEMS_PER_VIEW = 6

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const ABannerStatusDesktop: FC<IFProps> = ({ currentUser }) => {
  const { ads, loading, error } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.RECOMMEND_ACTIVITY_A.PC,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (loading || error || !ads?.length) {
    return (
      <>
        {new Array(RECOMMEND_A_BANNER_ITEMS_PER_VIEW)
          .fill('')
          .map((_, index) => (
            <LoadingWrapper key={`a-banner-loading-${index}`}>
              <Skeleton className="skeleton-rect" />
            </LoadingWrapper>
          ))}
      </>
    )
  }
  return (
    <>
      {ads?.map((ad) => (
        <ABannerDesktop
          key={ad.activity.id}
          ad={ad}
          currentUser={currentUser}
        />
      ))}
    </>
  )
}

export default ABannerStatusDesktop

const LoadingWrapper = styled.div`
  width: 100%;
  max-width: 276px;
  padding: 0 12px 50px 12px;
  flex-shrink: 0;

  .skeleton-rect {
    display: flex;
    gap: 10px;
    width: 100%;
    height: 360px;
  }
`
