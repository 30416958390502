import { useEffect, useMemo, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import '../style/sass/linkareer-styles.scss'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'swiper/swiper.min.css'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import cookie from 'cookie'
import mediaQuery from 'css-mediaquery'
import get from 'lodash/get'
import { NextComponentType } from 'next'
import { DefaultSeo } from 'next-seo'
import App, { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import { RecoilRoot } from 'recoil'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { UAParser } from 'ua-parser-js'
import { ChattingProvider } from '@app.feature/chatting/chattingContext/module/ChattingContext'
import { EXCEPT_PATH_SEO, EXCEPT_PATH_FLUTTER } from 'constants/exceptPath'
import { DEFAULT_META } from 'constants/metaData'
import { IS_IOS_NATIVE } from 'constants/storeKeys'
import { CurrentUserProvider } from 'hooks/useCurrentUser'
import { initializeApollo, useApollo } from 'lib/apolloClient'
import styledTheme from 'style/styledTheme'
import { init as apmInit } from 'utils/apm'
import { initFirebaseAndMessage } from 'utils/firebase'
import reportWebVitalsUtil from 'utils/reportWebVitals'
import { CustomAppContext } from 'utils/type'
import CustomerMessageFormContainer from '../components/customer-message-form'
import { Domains, DomainsContext, UAContext, WebviewContext } from '../context'
import useFlutterInappwebview from '../hooks/useFlutterInappwebview'
import generateTheme from '../style/theme'
import {
  LINKAREER_ACADEMY_COMMUNITY_BASE_URL,
  LINKAREER_API_BASE_URL,
  LINKAREER_DOMAIN,
  NODE_ENV,
  PROTOCOL,
  XEN_HOSTNAME,
} from '../utils/config'

const OfflineToast = dynamic(() => import('components/OfflineToast'), {
  ssr: false,
})

const isSsr = typeof window === 'undefined'
const isBrowser = !isSsr

if (isBrowser) {
  apmInit()
}

interface LinkareerAppProps extends AppProps {
  Component: NextComponentType
  pageProps: any
  err: any
  ua: string
  isIOSNative: boolean
  domains: Domains
  deviceType: string
  browserName: string
  asPath?: string
  cookies: Record<string, string>
}

const domain = isSsr ? LINKAREER_DOMAIN : window.location.hostname

const isProduction = domain?.includes('linkareer.com') || false
const queryParams = isSsr ? '' : window.location.pathname

const LinkareerAppComponent = ({
  isIOSNative,
  Component,
  pageProps,
  domains,
  deviceType,
  browserName,
  err,
  asPath,
  ua,
  cookies,
}: LinkareerAppProps) => {
  const router = useRouter()
  const { client: apolloClient } = useApollo(pageProps)
  const { isWebview, fluterInappwebview } = useFlutterInappwebview()
  const [appVersion] = useState(cookies.appVersion || '')

  const uaIsMobile = deviceType === 'mobile' || deviceType === 'tablet'

  useEffect(() => {
    // componentDidMount

    const handleRouteChange = (url: string) => {
      if (
        EXCEPT_PATH_FLUTTER.map((el) => asPath?.includes(el)).some((el) => el)
      ) {
        return
      }

      if (isWebview && !url.startsWith(window.location.pathname)) {
        fluterInappwebview.callHandler('handleUrlChange', url)
        // https://github.com/vercel/next.js/discussions/32231#discussioncomment-1766651
        throw new Error('Abort')
      }
    }

    const iosNativeCheck = () => {
      if (isIOSNative) {
        localStorage.setItem(IS_IOS_NATIVE, 'true')
      }
    }
    const removeSsrStyles = () => {
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }

    const injectServiceWorkerAndFirebase = () => {
      if (
        isBrowser &&
        'serviceWorker' in navigator &&
        NODE_ENV === 'production'
      ) {
        try {
          initFirebaseAndMessage()
        } catch (error) {
          console.debug('[ServiceWorker] 등록 실패 : ', error)
        }
      }
    }

    iosNativeCheck()
    removeSsrStyles()

    injectServiceWorkerAndFirebase()

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const defaultSeoImages = useMemo(() => {
    if (EXCEPT_PATH_SEO.map((el) => asPath?.includes(el)).some((el) => el)) {
      return []
    }

    return DEFAULT_META.images
  }, [asPath])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />
        <meta name="referrer" content="same-origin" />
      </Head>
      <DefaultSeo
        title={DEFAULT_META.title}
        description={DEFAULT_META.description}
        openGraph={{
          title: DEFAULT_META.title,
          description: DEFAULT_META.description,
          type: 'website',
          locale: 'ko_KR',
          site_name: '링커리어',
          images: defaultSeoImages,
          url: domains
            ? `https://${domains.linkareer}${asPath || queryParams}`
            : DEFAULT_META.url,
        }}
        twitter={{
          cardType: 'summary',
        }}
        dangerouslySetAllPagesToNoIndex={isProduction ? undefined : true}
        dangerouslySetAllPagesToNoFollow={isProduction ? undefined : true}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: DEFAULT_META.keywords,
          },
        ]}
        languageAlternates={[
          {
            href: `https://${domains.linkareer}${asPath || queryParams}`,
            hrefLang: 'ko-kr',
          },
        ]}
      />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider
          theme={generateTheme({
            ssrMatchMedia: (query: string) => {
              return {
                matches: mediaQuery.match(query, {
                  // The estimated CSS width of the browser.
                  width: deviceType === 'mobile' ? '0px' : '1280px',
                }),
              } as any
            },
          })}
        >
          <StyledThemeProvider theme={{ ...styledTheme, isMobile: uaIsMobile }}>
            <CookiesProvider>
              <WebviewContext.Provider
                value={{
                  isWebview,
                  fluterInappwebview,
                  appVersion,
                }}
              >
                <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
                  <DomainsContext.Provider value={domains || {}}>
                    <UAContext.Provider
                      value={{
                        browserName,
                        uaString: ua,
                        isMobile: uaIsMobile,
                      }}
                    >
                      <CustomerMessageFormContainer {...pageProps}>
                        <ChattingProvider>
                          <RecoilRoot>
                            <DndProvider
                              backend={uaIsMobile ? TouchBackend : HTML5Backend}
                            >
                              <CurrentUserProvider>
                                <>
                                  <Component {...pageProps} err={err} />
                                  <CssBaseline />
                                  <OfflineToast />
                                </>
                              </CurrentUserProvider>
                            </DndProvider>
                          </RecoilRoot>
                        </ChattingProvider>
                      </CustomerMessageFormContainer>
                      <div id="modal" />
                    </UAContext.Provider>
                  </DomainsContext.Provider>
                </SnackbarProvider>
              </WebviewContext.Provider>
            </CookiesProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  )
}

LinkareerAppComponent.getInitialProps = async (
  appContext: CustomAppContext,
) => {
  const { ctx } = appContext

  const apolloClient = initializeApollo()
  appContext.ctx.apolloClient = apolloClient

  const appProps = await App.getInitialProps(appContext)

  const domains = {
    xen: XEN_HOSTNAME,
    protocol: PROTOCOL,
    linkareer: LINKAREER_DOMAIN,
    linkareerAPIBaseURL: LINKAREER_API_BASE_URL,
    academyCommunityBaseUrl: LINKAREER_ACADEMY_COMMUNITY_BASE_URL,
  }

  if (ctx.res && ctx.res.finished) {
    // When redirecting, the response is finished.
    // No point in continuing to render
    return {}
  }

  const initialCookies = ctx.req?.headers.cookie || ''
  const parsedCookies = cookie.parse(initialCookies)

  let deviceType = 'desktop'
  let browserName = ''
  let ua = ''
  const parser = new UAParser(
    ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent,
  )

  ua = parser.getUA()
  deviceType = parser.getDevice().type || 'desktop'
  browserName = parser.getBrowser().name || ''
  const asPath = appContext?.ctx?.asPath
  if (ctx?.res) {
    ctx.res.setHeader('Cache-Control', `max-age=${10 * 60}`)
  }

  const isIOSNative = get(
    appContext.router.query,
    'is_ios_native_login_available',
    false,
  )

  // 로그인된 요청은 캐싱을 안한다.
  if (ctx.res) {
    const { apolloClient } = ctx
    // @ts-ignore
    const currentUser = apolloClient?.cache?.data?.data?.ROOT_QUERY?.currentUser

    if (currentUser) {
      ctx.res.setHeader('Cache-Control', 'no-cache, no-store')
    }
  }

  return {
    ...appProps,
    deviceType,
    browserName,
    asPath,
    isIOSNative,
    domains,
    ua,
    cookies: parsedCookies,
  }
}

export const reportWebVitals = reportWebVitalsUtil

export default LinkareerAppComponent
