import { FC, useRef } from 'react'
import styled from 'styled-components'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { UseGetAdsByPlacementCode_AdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useSwiperAdViewportTracking from '@app.feature/ad/hooks/useSwiperAdViewportTracking'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import HotLineBannerItemMobile from './item/HotLineBannerItem.mobile'

interface IFProps {
  ads: Array<UseGetAdsByPlacementCode_AdFragment>
}

const HotLineBannerListMobile: FC<IFProps> = ({ ads }) => {
  const swiperRef = useRef<SwiperClass | null>(null)

  const trackImpression = (index: number) => {
    const currentAd = ads[index]
    if (currentAd?.campaign?.id) {
      pushGTMCampaignId({
        eventName: GTM_EVENT.HOTLINE.MOBILE?.IMPRESSION_EVENT || '',
        campaignId: currentAd.campaign.id,
      })
    }
  }

  const { containerRef, isVisible } = useSwiperAdViewportTracking({
    swiperRef: swiperRef,
    onVisible: trackImpression,
  })

  const handleRealIndexChange = (swiper: SwiperClass) => {
    if (isVisible) {
      trackImpression(swiper.realIndex)
    }
  }

  return (
    <StyledWrapper ref={containerRef}>
      <Swiper
        key="hotline-banner-mobile-swiper"
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
        onRealIndexChange={handleRealIndexChange}
      >
        {ads.map((ad, index) => {
          return (
            <SwiperSlide key={`hotline-banner-mobile-placement-${ad.id}`}>
              <HotLineBannerItemMobile
                key={ad.id}
                ad={ad}
                totalSides={ads.length}
                activeSlide={index + 1}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

export default HotLineBannerListMobile

const StyledWrapper = styled.div`
  position: relative;
  padding: 15px 0;
  max-width: none;
  max-height: none;
`
