import { FC } from 'react'
import styled from 'styled-components'
import ActivityLoadingItem from '../../../../home/components/ActivityLoadingItem/ActivityLoadingItem'

const POPULAR_ACTIVITY_ITEM_PER_VIEW = 4

const PopularActivityLoadingDesktop: FC = () => {
  return (
    <StyledWrapper>
      <h2 className="title-text">금주의 인기공고</h2>
      <div className="loading-wrapper">
        <ActivityLoadingItem
          time={POPULAR_ACTIVITY_ITEM_PER_VIEW}
          className="loading-item"
        />
      </div>
    </StyledWrapper>
  )
}

export default PopularActivityLoadingDesktop

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 30px;
  background-color: #fafafa;
  gap: 20px;
  .title-text {
    font-size: 20px;
    font-weight: bold;
  }
  .loading-wrapper {
    display: flex;
    gap: 24px;
    .loading-item {
      width: 100%;
    }
  }
`
