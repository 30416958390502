import { FC } from 'react'
import styled from 'styled-components'
import { ActivityScrap_CurrentUserFragment } from 'generated/graphql'
import ABannerStatusDesktop from './ABanner/ABannerStatus.desktop'
import SBannerStatusDesktop from './SBanner/SBannerStatus.desktop'

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const RecommendBannerListDesktop: FC<IFProps> = ({ currentUser }) => {
  return (
    <StyledWrapper>
      <h2 className="activity-title">링커리어 추천활동</h2>
      <div className="activity-banner-container">
        <SBannerStatusDesktop currentUser={currentUser} />
        <ABannerStatusDesktop currentUser={currentUser} />
      </div>
    </StyledWrapper>
  )
}

export default RecommendBannerListDesktop

const StyledWrapper = styled.div`
  .activity-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.96px;
    padding-bottom: 30px;
  }
  .activity-banner-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: calc(100% + 24px);
    margin: -12px;
  }
`
