import styled from 'styled-components'
import InputText from '@app.feature/signup/signup/components/inputFields/InputText'
import FormLabel from '@app.feature/signup/signup/desktop/components/FormLabel'
import { changeInputToOnlyNumber } from '../../module/utils'

const FieldBirthday = () => {
  return (
    <StyledWrapper>
      <FormLabel label="생년월일" />
      <InputText
        fieldName="birthday"
        placeholder="YYYYMMDD"
        onInputChange={changeInputToOnlyNumber}
      />
    </StyledWrapper>
  )
}
export default FieldBirthday

const StyledWrapper = styled.div`
  display: grid;
  gap: 9px;
`
