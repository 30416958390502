import { ChangeEvent, InputHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import Image from 'next/image'
import styled, { css } from 'styled-components'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: string
  placeholder: string
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
}
const InputText = ({
  fieldName,
  placeholder,
  onInputChange: handleInputChange,
}: IProps) => {
  const {
    register,
    formState: { errors, dirtyFields },
  } = useFormContext()

  const errorMessage = dirtyFields[fieldName] && errors?.[fieldName]?.message

  return (
    <StyledWrapper>
      <input
        type="text"
        className={`join-input ${!!errorMessage && 'error-input'}`}
        placeholder={placeholder}
        autoComplete="off"
        {...register(fieldName, { onChange: handleInputChange })}
      />
      <span className={`input-message ${!!errorMessage && 'error-message'}`}>
        {errorMessage}
      </span>
      {dirtyFields[fieldName] && !errors?.[fieldName]?.message && (
        <i className="check-svg">
          <Image
            src={`/static/images/signup/ic-check-blue.svg`}
            width={14}
            height={10}
            alt="checked-interest"
          />
        </i>
      )}
    </StyledWrapper>
  )
}

export default InputText

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    .join-input {
      width: 100%;
      height: 50px;
      padding: 0 16px;
      border-radius: 8px;
      border: solid 1px ${theme.line.line_2};
      font-size: 16px;
      &:focus {
        outline: none;
        border: solid 1px ${theme.color.primary_2};
      }
      &.error-input {
        border: solid 1px ${theme.color.error_1};
      }
    }
    > span.input-message {
      display: block;
      height: 17px;
    }
    .error-message {
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: -0.48px;
      text-align: left;
      color: ${theme.color.error_1};
    }
    i.check-svg {
      position: absolute;
      top: calc(50% - 14px);
      right: 20px;
      display: flex;
    }
  `}
`
