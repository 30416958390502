import { memo } from 'react'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '../constants/adCode'
import useGetAdsByPlacementCode from '../hooks/useGetAdsByPlacementCode'
import HotLineBannerListDesktop from './HotLineBanner/HotLineBannerList.desktop'

const HotLineBannerStatusDesktop = () => {
  const { ads, loading, error } = useGetAdsByPlacementCode({
    code: AD_CODE_ID.HOTLINE.PC,
    isShuffled: true,
  })

  if (loading || error || !ads?.length) {
    return <Skeleton width={720} height={120} />
  } else {
    return <HotLineBannerListDesktop ads={ads} />
  }
}

export default memo(HotLineBannerStatusDesktop)
