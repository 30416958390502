import { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ScrapBoxButton from 'components/ActivityListCardItem/ScrapBoxButton'
import Link from 'components/Link'
import { cloudinaryURL } from 'utils/cloudinary'

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
  totalSlide: number
  activeSlide: number
}

const RecommendBannerCardImageMobile: FC<IFProps> = ({
  currentUser,
  ad,
  totalSlide,
  activeSlide,
}) => {
  const linkProps = {
    href: `/activity/[id]`,
    as: `/activity/${ad.activity.id}`,
    naked: true,
    ...(ad?.ad?.campaign?.landingURL && {
      onClick: () => {
        const eventName =
          (ad.ad?.campaign?.unifiedAdPlacement.code ===
          'recommend-activity-s-banner'
            ? GTM_EVENT.RECOMMEND_ACTIVITY_S.MOBILE?.CLICK_EVENT
            : GTM_EVENT.RECOMMEND_ACTIVITY_A.MOBILE?.CLICK_EVENT) || ''

        pushGTMCampaignId({
          eventName,
          campaignId: ad.ad?.campaign?.id || '',
        })

        window.open(
          ad?.ad?.campaign?.landingURL,
          '_blank',
          'noopener, noreferrer',
        )
      },
    }),
  }

  const thumbnailUrl = getActivityListFromAdsImageUrl({
    adImage: ad.ad?.image?.url,
    activity: ad.activity,
  })

  return (
    <StyledWrapper>
      <Link {...linkProps}>
        <div className="img-wrapper">
          <Image
            className="activity-image"
            alt={ad.activity?.title || ''}
            src={cloudinaryURL(thumbnailUrl)}
            layout="fill"
            objectFit="cover"
            objectPosition="top"
            unoptimized
          />
          <div className="custom-pagination">{`${activeSlide}/${totalSlide}`}</div>
        </div>
      </Link>
      <ScrapBoxButton
        activity={ad.activity}
        className="scrap-button"
        currentUser={currentUser ?? undefined}
      />
    </StyledWrapper>
  )
}

export default RecommendBannerCardImageMobile

const StyledWrapper = styled.div`
  .scrap-button {
    z-index: 10;
    top: 8px;
    padding: 3px 8px;
    right: 8px;
  }

  .img-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 58%;
  }

  .activity-image {
    width: 100% !important;
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  .custom-pagination {
    position: absolute;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    bottom: 8px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    right: 8px;
  }
`
