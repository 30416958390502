import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { useCheckSmsAuthCodeMutation } from 'generated/graphql'
import { changeInputToOnlyNumber } from '@app.feature/signup/signup/module/utils'
import { SignupFormType } from '@app.feature/signup/signup/types/signupFormType'
import { UserInfoModalType } from '@app.feature/userInfoModal/type/userInfoModalType'
import useTimer from '@app.modules/hooks/useTimer'
import ERROR_MESSAGE from 'constants/errorMessages'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import { getErrorCode } from 'utils/apolloErrorParser'
import PhoneNumberButton from './PhoneNumberButton'

interface IFProps {
  isVerified: boolean
  onClickVerify: () => void
  verificationToken?: string
}

const FieldVerification = ({
  isVerified,
  onClickVerify,
  verificationToken,
}: IFProps) => {
  const {
    watch,
    register,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext<SignupFormType | UserInfoModalType>()
  const { enqueueApolloError } = useErrorSnackbar()

  const inputPhone = watch('phoneInput') || ''
  const inputPhoneVerification = watch('phoneVerification') || ''

  const [checkSmsAuthCode, { data }] = useCheckSmsAuthCodeMutation({
    onCompleted: () => {
      setValue('smsAuthedToken', data?.checkSmsAuthCode.token ?? '')
      onClickVerify()
      handleClearTimer()
    },
    onError: (err: ApolloError) => {
      const errorCode = getErrorCode(err)
      if (errorCode) {
        setError('phoneVerification', {
          message: ERROR_MESSAGE[errorCode],
        })
      } else {
        enqueueApolloError()
      }
    },
  })
  const THREE_MINUTES = 3 * 60
  const { leftTime, isExpire, handleClearTimer, isCleared } =
    useTimer(THREE_MINUTES)

  const { query } = useRouter()

  useEffect(() => {
    if (isExpire && !isCleared) {
      setError('phoneVerification', {
        message: '인증시간이 만료되었습니다. 다시 시도해주세요',
      })
    }
  }, [isExpire])

  const getButtonState = () => {
    if (isVerified) {
      return 'verified'
    } else if (
      !isExpire &&
      !errors.phoneVerification?.message &&
      inputPhoneVerification.length === 6
    ) {
      return 'active'
    } else {
      return 'inactive'
    }
  }

  const handleClickVerify = () => {
    checkSmsAuthCode({
      variables: {
        input: {
          code: inputPhoneVerification,
          token: verificationToken || (query.token as string),
          isSignupSession: query.token ? true : false, // 회원가입에서의 문자 인증에서만 True
          phoneNumber: inputPhone,
        },
      },
    })
  }

  return (
    <StyledWrapper>
      <div className="verification-container">
        <input
          type="text"
          maxLength={6}
          className={'phone-input '}
          placeholder={'인증번호를 입력해주세요'}
          autoComplete="off"
          disabled={isVerified}
          autoFocus
          {...register('phoneVerification', {
            onChange: changeInputToOnlyNumber,
          })}
        />
        {!isVerified && <span className="timer">{leftTime}</span>}
      </div>

      <PhoneNumberButton
        status={getButtonState()}
        disabled={
          isVerified || !!errors.phoneVerification || leftTime === '00:00'
        }
        buttonText="확인"
        onClickButton={handleClickVerify}
      />
    </StyledWrapper>
  )
}

export default FieldVerification

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 5px;
    margin-top: 10px;
    .verification-container {
      flex: 1;
      position: relative;
      > .phone-input {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        border: solid 1px ${theme.line.line_2};
        font-size: 16px;
        &:focus {
          outline: none;
          border: solid 1px ${theme.color.primary_2};
        }
      }
      > .timer {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        letter-spacing: -0.64px;
        color: ${theme.color.error_1};
      }
    }
  `}
`
