import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'

const RecommendBannerCardLoadingMobile = () => {
  return <SkeletonWrapper />
}

export default RecommendBannerCardLoadingMobile

const SkeletonWrapper = styled(Skeleton)`
  padding-top: 58%;
`
