import { FC } from 'react'
import { ActivityListCardItem_CurrentUserFragment } from 'generated/graphql'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import PopularActivityMobile from '@app.feature/ad/mobile/PopularActivity/PopularActivity.mobile'
import PopularActivityLoadingMobile from './loading/PopularActivityLoading.mobile'

interface IFProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  isScreenQueryLoading: boolean
}

const PopularActivityStatusMobile: FC<IFProps> = ({
  currentUser,
  isScreenQueryLoading,
}) => {
  const { ads, loading, error } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.WEEKLY_POPULAR.MOBILE!,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (isScreenQueryLoading || loading || error) {
    return <PopularActivityLoadingMobile />
  }

  if (!ads?.length) {
    return null
  }

  return <PopularActivityMobile currentUser={currentUser} ads={ads} />
}

export default PopularActivityStatusMobile
