import { FC } from 'react'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useAdImpressionTracker from '@app.feature/ad/hooks/useAdImpressionTracker'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListCardItem from 'components/ActivityListCardItem'

interface IFProps {
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const ABannerDesktop: FC<IFProps> = ({ ad, currentUser }) => {
  useAdImpressionTracker({
    eventName: GTM_EVENT.RECOMMEND_ACTIVITY_A.PC.IMPRESSION_EVENT,
    campaignId: ad.ad?.campaign?.id || '',
  })

  const handlePushGtmEvent = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    if (!ad.ad) {
      return
    }
    pushGTMCampaignId({
      eventName: GTM_EVENT.RECOMMEND_ACTIVITY_A.PC.CLICK_EVENT,
      campaignId: ad.ad?.campaign?.id || '',
    })
  }

  return (
    <StyledWrapper>
      <ActivityListCardItem
        activity={ad.activity}
        cardTitle={ad.activity?.title}
        currentUser={currentUser ?? undefined}
        cardImageProps={{
          src: getActivityListFromAdsImageUrl({
            adImage: ad?.ad?.image?.url,
            activity: ad?.activity,
          }),
          width: 360,
          alt: ad.activity?.title || '',
        }}
        managerBadgeOn={false}
        {...(ad.ad?.campaign?.landingURL && {
          linkProps: {
            onClick: () => {
              handlePushGtmEvent(ad)
              window.open(ad.ad?.campaign?.landingURL || '/')
            },
          },
        })}
        onClick={() => handlePushGtmEvent(ad)}
      />
    </StyledWrapper>
  )
}

export default ABannerDesktop

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 276px;
  padding: 0 12px 50px 12px;
  flex-shrink: 0;
`
