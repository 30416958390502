import { useFormContext } from 'react-hook-form'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { SignupFormType } from '@app.feature/signup/signup/types/signupFormType'
import { ACTIVITY_TYPE_TEXT, ACTIVITY_TYPE_TEXT_ID } from 'constants/typeText'

interface IProps {
  name: string
  checked?: boolean
}

const ActivityContainer = ({ name, checked }: IProps) => {
  const { register } = useFormContext<SignupFormType>()
  const id = ACTIVITY_TYPE_TEXT_ID[name]
  const labelName = ACTIVITY_TYPE_TEXT[id]

  return (
    <StyledWrapper>
      <input
        type="checkbox"
        id={`activity-${name}`}
        value={id}
        checked={checked}
        {...register('preferActivityTypes')}
      />
      <label htmlFor={`activity-${name}`} className="pointer">
        <Image
          src={`/static/images/signup/interest-group-${name}.svg`}
          width={40}
          height={40}
          alt={name}
        />
        <i className="check-svg">
          <Image
            src={`/static/images/signup/ic-check-white.svg`}
            width={12}
            height={9}
            alt="checked-interest"
          />
        </i>
      </label>
      <p>{labelName}</p>
    </StyledWrapper>
  )
}
export default ActivityContainer

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    label {
      width: 100%;
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: solid 1px ${theme.line.line_2};
      margin-bottom: 10px;
    }
    .check-svg {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 22px;
      height: 22px;
      border-radius: 0 8px 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > input {
      display: none;
      &:checked + label {
        background-color: ${theme.background.bg_4};
        border: 1px solid ${theme.color.primary_2};
        > .check-svg {
          display: flex;
          background-color: ${theme.color.primary_2};
        }
      }
    }
    > p {
      font-family: NotoSansKR;
      font-size: 14px;
      letter-spacing: -0.7px;
      text-align: center;
      color: #333;
    }
  `}
`
