import { ApolloError } from '@apollo/client'
import {
  UseGetAdsByPlacementCode_AdFragment,
  useGqlAdsByPlacementCodeQuery,
} from 'generated/graphql'
import shuffleList from '@app.feature/ad/modules/shuffleList'
import { AD_CODE } from '../constants/adCode'

type TUseGetAdByPlacementCodeReturns = {
  ads?: Array<UseGetAdsByPlacementCode_AdFragment>
  loading: boolean
  error?: ApolloError
}

type TUseGetAdByPlacementCodeParams = {
  code: AD_CODE
  isShuffled?: boolean
}

type TUseGetAdsByPlacementCodeFn = (
  params: TUseGetAdByPlacementCodeParams,
) => TUseGetAdByPlacementCodeReturns

/**
 * 여러개의 광고 지면을 응답받아야 하는 경우 사용합니다.
 * @param TUseGetAdByPlacementCodeParams
 * @returns
 */
const useGetAdsByPlacementCode: TUseGetAdsByPlacementCodeFn = ({
  code,
  isShuffled = false,
}) => {
  const { data, loading, error } = useGqlAdsByPlacementCodeQuery({
    variables: {
      code,
    },
  })

  return {
    ads: isShuffled
      ? shuffleList<UseGetAdsByPlacementCode_AdFragment>(
          data?.adsByPlacementCode.nodes || [],
        )
      : data?.adsByPlacementCode?.nodes,
    loading,
    error,
  }
}

export default useGetAdsByPlacementCode
