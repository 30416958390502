import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import MagnifyingGlassIcon from 'svgIcons/MagnifyingGlass'
import { isSpamSearchInput } from 'utils/spamKeywordsUtils'

interface IFProps {
  isMainPage: boolean
}

type TForm = {
  search: string
}

const SearchBarDesktop: FC<IFProps> = ({ isMainPage }) => {
  const router = useRouter()
  const { register, handleSubmit } = useForm<TForm>()

  const onSubmit = (data: TForm) => {
    if (!data.search) return
    if (isSpamSearchInput([data.search])) {
      return
    }

    router.push({
      pathname: '/search',
      query: {
        ...router.query,
        q: data.search,
        p: 1,
      },
    })
  }

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <input
          id="header-search"
          type="search"
          placeholder="대외활동, 인턴, 채용 정보를 검색해보세요"
          defaultValue={router.query.q || ''}
          data-page-status={isMainPage ? 'main' : 'other'}
          {...register('search')}
        />
        <button className="search-icon-btn">
          <MagnifyingGlassIcon />
        </button>
      </form>
    </StyledWrapper>
  )
}

export default SearchBarDesktop

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    .form {
      position: relative;
      display: flex;

      input {
        height: 54px;
        border: 2px solid ${theme.color.primary_2};
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 0 45px 0 15px;
        outline: none;
        -webkit-appearance: textfield; // ios 에서 input type search일 경우 나타나는 돋보기 스타일 제거
        &::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        &[data-page-status='main'] {
          width: 550px;
        }
        &[data-page-status='other'] {
          width: 370px;
        }
      }

      .search-icon-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  `}
`
