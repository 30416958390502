import { ApolloError } from '@apollo/client'
import {
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  useGqlActivityListFromAdsByPlacementCodeQuery,
} from 'generated/graphql'

type TUseGetActivityListFromAdsByPlacementCodeFn = (params: {
  adPlacementCode: string
  restVariables?: {
    withManager?: boolean
    isActivityCard?: boolean
    isActivityListItem?: boolean
  }
}) => {
  ads?: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  loading: boolean
  error?: ApolloError
}

const useGetActivityListFromAdsByPlacementCode: TUseGetActivityListFromAdsByPlacementCodeFn =
  ({ adPlacementCode, restVariables }) => {
    const { data, loading, error } =
      useGqlActivityListFromAdsByPlacementCodeQuery({
        variables: {
          adPlacementCode,
          ...(restVariables && restVariables),
        },
      })

    return {
      ads: data?.activityListFromAdsByPlacementCode,
      loading,
      error,
    }
  }

export default useGetActivityListFromAdsByPlacementCode
