import { FC } from 'react'
import styled from 'styled-components'
import {
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ActivityListCardItem_CurrentUserFragment,
} from 'generated/graphql'
import PopularRecruitItemMobile from './PopularRecruitItem.mobile'

interface IFProps {
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityListCardItem_CurrentUserFragment
}

const PopularRecruitMobile: FC<IFProps> = ({ ads, currentUser }) => {
  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      <ul className="card-list">
        {ads?.map((ad) => {
          return (
            <PopularRecruitItemMobile
              key={ad.activity.id}
              ad={ad}
              currentUser={currentUser}
            />
          )
        })}
      </ul>
    </StyledWrapper>
  )
}

export default PopularRecruitMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .title-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding-right: 20px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
