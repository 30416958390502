import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import {
  ActivityListCardItem_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useSwiperAdViewportTracking from '@app.feature/ad/hooks/useSwiperAdViewportTracking'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListCardItem from 'components/ActivityListCardItem'
import ArrowLeft from 'svgIcons/ArrowLeft'
import ArrowRight from 'svgIcons/ArrowRight'

interface IFPopularActivityDesktopProps {
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityListCardItem_CurrentUserFragment
}

const POPULAR_ACTIVITY_SLIDES_PER_VIEW = 6

const PopularActivityDesktop: FC<IFPopularActivityDesktopProps> = ({
  ads,
  currentUser,
}) => {
  /**
   * Swiper의 부드러운 loop 애니메이션을 위해 필요한 경우 슬라이드 데이터를 복제합니다.
   * Swiper의 loop 모드에서는 네비게이션 시 시각적인 결함을 방지하기 위해
   * slidesPerView의 최소 2배 이상의 아이템이 필요합니다.
   *
   * @note Swiper 공식 문서 참고: "slidesPerView: 'auto'는 현재 multirow 모드(grid.rows > 1)와
   * 호환되지 않습니다."
   *
   * @param ads - 원본 슬라이드 데이터
   * @returns 원본 데이터의 길이가 slidesPerView와 slidesPerView * 2 사이일 경우 복제된 데이터를 반환하고,
   *          그 외의 경우 원본 데이터를 반환
   */
  const duplicatedAds =
    POPULAR_ACTIVITY_SLIDES_PER_VIEW < ads.length &&
    ads.length < POPULAR_ACTIVITY_SLIDES_PER_VIEW * 2
      ? [...ads, ...ads]
      : ads

  const swiperRef = useRef<SwiperClass | null>(null)
  const visibleAdsRef = useRef(new Set())
  const isInitialLoadRef = useRef(true)

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const handlePushGtmEvent = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    if (!ad.ad) {
      return
    }
    pushGTMCampaignId({
      eventName: GTM_EVENT.WEEKLY_POPULAR.PC.CLICK_EVENT,
      campaignId: ad.ad?.campaign?.id || '',
    })
  }

  const handleOnClickOutLink = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    handlePushGtmEvent(ad)
    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad?.campaign?.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  const trackImpression = (index: number) => {
    if (!swiperRef.current) return

    const visibleSlideIndexes = Array.from(
      { length: POPULAR_ACTIVITY_SLIDES_PER_VIEW },
      (_, i) => (index + i) % duplicatedAds.length,
    )

    const currentVisibleAdIds = new Set()

    visibleSlideIndexes.forEach((index) => {
      const currentAd = duplicatedAds[index]
      const campaignId = currentAd?.ad?.campaign?.id

      if (!campaignId) {
        return
      }

      currentVisibleAdIds.add(campaignId)

      if (!visibleAdsRef.current.has(campaignId)) {
        pushGTMCampaignId({
          eventName: GTM_EVENT.WEEKLY_POPULAR.PC.IMPRESSION_EVENT,
          campaignId: campaignId,
        })
      }
    })

    visibleAdsRef.current = currentVisibleAdIds
  }

  const { containerRef, isVisible } = useSwiperAdViewportTracking({
    swiperRef: swiperRef,
  })

  useEffect(() => {
    if (isVisible && swiperRef.current) {
      if (isInitialLoadRef.current) {
        isInitialLoadRef.current = false
      }
      visibleAdsRef.current.clear()
      trackImpression(swiperRef.current.realIndex)
    }
  }, [isVisible])

  const handleRealIndexChange = (swiper: SwiperClass) => {
    if (isVisible) {
      trackImpression(swiper.realIndex)
    }
  }

  return (
    <StyledWrapper ref={containerRef}>
      <section className="title-section">
        <h2 className="title-text">금주의 인기공고</h2>
        <article className="button-article">
          <button onClick={handlePrevClick}>
            <ArrowLeft />
          </button>
          <button onClick={handleNextClick}>
            <ArrowRight />
          </button>
        </article>
      </section>
      <Swiper
        modules={[Autoplay, Navigation]}
        loop
        slidesPerView={6}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        onRealIndexChange={handleRealIndexChange}
      >
        {duplicatedAds?.map((ad, index) => {
          const isAd = ad?.ad?.campaign
          return (
            <SwiperSlide
              key={`popular-activity-item-${ad.activity.id}-${index}`}
              className="slide-item"
              {...(!!ad.ad && {
                'aria-label': 'Advertisement',
                role: 'advertisement',
              })}
            >
              <ActivityListCardItem
                managerBadgeOn={false}
                activity={ad.activity}
                cardTitle={ad.activity?.title}
                currentUser={currentUser}
                cardImageProps={{
                  width: 155,
                  src: getActivityListFromAdsImageUrl({
                    adImage: ad.ad?.image?.url,
                    activity: ad.activity,
                  }),
                  alt: ad.activity?.title || '',
                }}
                {...(isAd && {
                  linkProps: {
                    onClick: () => handleOnClickOutLink(ad),
                  },
                })}
                onClick={() => handlePushGtmEvent(ad)}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

export default PopularActivityDesktop

const StyledWrapper = styled.div`
  border-radius: 5px;
  padding: 30px 0 40px 30px;
  background-color: #fafafa;
  .title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    margin-bottom: 15px;
    .title-text {
      font-size: 20px;
      font-weight: bold;
    }
    .button-article {
      display: flex;
      gap: 5px;
      button {
        padding: 8px 12px;
        border-radius: 3px;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        cursor: pointer;
        svg {
          width: 6px;
          height: 10px;
        }
      }
    }
  }
  .loading-wrapper {
    display: flex;
    gap: 24px;
    .loading-item {
      width: 100%;
    }
  }

  .swiper-wrapper {
    display: flex;
    gap: 24px;
    .slide-item {
      max-width: 155px;
    }
  }
`
