import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { format } from 'date-fns'
import { useRouter } from 'next/router'
import {
  UseGetAdByPlacementCodeAndCurrentTime_AdFragment,
  useGqlCurrentAdByPlacementCodeAndCurrentTimeQuery,
} from 'generated/graphql'
import { AD_CODE } from '../constants/adCode'

type TUseGetAdByPlacementCodeAndCurrentTimeReturns = {
  ad?: UseGetAdByPlacementCodeAndCurrentTime_AdFragment | null
  loading: boolean
  error?: ApolloError
}

type TUseGetAdByPlacementCodeAndCurrentTimeParams = {
  code: AD_CODE
}

type TUseGetAdByPlacementCodeAndCurrentTimeFn = (
  params: TUseGetAdByPlacementCodeAndCurrentTimeParams,
) => TUseGetAdByPlacementCodeAndCurrentTimeReturns

/**
 * 단일한 랜덤의 광고 지면을 응답받아야 하는 경우 사용합니다.
 * @param  TUseGetAdByPlacementCodeAndCurrentTimeParams
 * @returns
 */
const useGetAdByPlacementCodeAndCurrentTime: TUseGetAdByPlacementCodeAndCurrentTimeFn =
  ({ code }) => {
    const router = useRouter()
    const { page } = router.query

    const currentTime = useMemo(() => {
      const currentDate = new Date()
      return format(currentDate, 'HH:mm:ss')
    }, [page])

    const { data, loading, error } =
      useGqlCurrentAdByPlacementCodeAndCurrentTimeQuery({
        variables: {
          code,
          currentTime,
        },
      })

    return {
      ad: data?.currentAdByPlacementCodeAndCurrentTime,
      loading,
      error,
    }
  }

export default useGetAdByPlacementCodeAndCurrentTime
