import { FC, useState } from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { UrlObject } from 'url'
import { UserType } from 'generated/graphql'
import { AD_INQUIRY_FORM_URL } from 'app.constants/adInquiry'
import { CAREER_MANAGEMENT_PATH_NAME } from 'app.constants/careerPathname'
import {
  NAV_BAR_MENU_ITEMS,
  TNavBar,
} from 'app.layout/constant/pageLayout.type'
import { useIsStucked } from 'hooks/useGnbStucked'
import GiveCardIcon from 'svgIcons/GiveCard'
import GnbNavbarOpenIcon from 'svgIcons/GnbNavbarOpenIcon'
import RecruitRegisterIcon from 'svgIcons/RecruitRegisterIcon'
import NavBarLinkWithIcon from './components/NavBarLinkWithIcon'
import NavMenuItem from './components/NavMenuItem'
import SpecManageButton from './components/SpecManageButton'
import SubNavSection from './components/SubNavSection'

interface IFProps extends TNavBar {
  isLogin: boolean
  userType?: UserType
}

const NavBarDesktop: FC<IFProps> = ({
  isLogin,
  activeKey,
  userType,
  isSticky = false,
}) => {
  const router = useRouter()
  const { isStucked, navbarRef } = useIsStucked<HTMLUListElement>(isSticky)
  const [isShowNavbarDetail, setIsShowNavbarDetail] = useState<boolean>(false)

  const handleToggleNavbarDetail = () => {
    setIsShowNavbarDetail((prev) => !prev)
  }

  const isMainPage = router.pathname === '/'
  const careerManagementUrl: UrlObject = isLogin
    ? {
        pathname: `${CAREER_MANAGEMENT_PATH_NAME}`,
        query: {
          section: 'info',
        },
      }
    : {
        pathname: '/login',
        query: {
          next: `${CAREER_MANAGEMENT_PATH_NAME}?section=info`,
        },
      }

  return (
    <StyledWrapper isSticky={isSticky}>
      <div className="nav-container">
        <section className="main-nav-section">
          <nav>
            <GnbNavbarOpenIcon
              onClick={handleToggleNavbarDetail}
              className="menu-detail-icon"
              isOpen={isShowNavbarDetail}
            />
            <ul className="nav-list" ref={navbarRef}>
              {NAV_BAR_MENU_ITEMS.map((menuItem, index) => {
                return (
                  <NavMenuItem
                    key={`${menuItem.label}-${index}`}
                    menuInfo={menuItem}
                    activeKey={activeKey}
                    isShowNavbarDetail={isShowNavbarDetail}
                    isStucked={isStucked}
                  />
                )
              })}
            </ul>
          </nav>
          <div className="nav-link-icon">
            <SpecManageButton
              activityHref={careerManagementUrl}
              activityLabel="스펙 정리하기"
              userType={userType}
              {...(!isLogin && {
                as: '/login',
              })}
            />
            <NavBarLinkWithIcon
              icon={<RecruitRegisterIcon height={20} width={20} />}
              activityHref="/manage/welcome"
              activityLabel="공고등록"
            />
            {isMainPage && (
              <NavBarLinkWithIcon
                icon={<GiveCardIcon />}
                activityHref={AD_INQUIRY_FORM_URL}
                activityLabel="광고 문의"
                anchorElementProps={{ target: '_blank' }}
              />
            )}
          </div>
        </section>
        {isShowNavbarDetail && <SubNavSection />}
      </div>
    </StyledWrapper>
  )
}

export default NavBarDesktop

type TStyleProps = {
  isSticky: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  ${({ theme, isSticky }) => css`
    position: ${isSticky ? 'sticky' : 'relative'};
    top: -1px;
    z-index: 100;
    background-color: ${theme.background.bg_8};
    box-shadow: 0 9px 9px -4px rgba(170, 170, 170, 0.16);
    border-top: 1px solid #eeeeee;

    > .nav-container {
      display: flex;
      flex-direction: column;

      > .main-nav-section {
        width: 100%;
        max-width: 1148px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        padding: 0 24px !important;

        > nav {
          height: 100%;
          display: flex;
          align-items: center;

          > .menu-detail-icon {
            width: 28px;
            height: 28px;
            margin-right: 32px;
          }

          & > .nav-list {
            height: 60px;
            display: flex;
            align-items: center;
            gap: 28px;
            flex-shrink: 0;
          }
        }
      }

      .nav-link-icon {
        display: flex;
        align-items: center;
      }
    }
  `}
`
