import { ACTIVITY_NOTICE_AD_CODE_KEY, AD_CODE_ID } from './adCode'

/**
 * GTM 이벤트 타입 정의
 * PC와 MOBILE 각각에 대한 CLICK_EVENT와 IMPRESSION_EVENT를 가집니다.
 */
type TGtmEventType = {
  PC: {
    CLICK_EVENT: string
    IMPRESSION_EVENT: string
  }
  MOBILE?: {
    CLICK_EVENT: string
    IMPRESSION_EVENT: string
  }
  APP?: {
    CLICK_EVENT: string
    IMPRESSION_EVENT: string
  }
}

export type TGtmEvent = Record<keyof typeof AD_CODE_ID, TGtmEventType>

/**
 * 광고 영역별 GTM 이벤트 정의
 */
export const GTM_EVENT: TGtmEvent = {
  MAIN: {
    PC: {
      CLICK_EVENT: 'pc_main_list_bnr_clk',
      IMPRESSION_EVENT: 'pc_main_list_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_main_list_bnr_clk',
      IMPRESSION_EVENT: 'mo_main_list_bnr_imp',
    },
  },
  HOTLINE: {
    PC: {
      CLICK_EVENT: 'pc_hotline_bnr_clk',
      IMPRESSION_EVENT: 'pc_hotline_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_hotline_bnr_clk',
      IMPRESSION_EVENT: 'mo_hotline_bnr_imp',
    },
  },
  RECOMMEND_ACTIVITY_S: {
    PC: {
      CLICK_EVENT: 'pc_rec_act_s_bnr_clk',
      IMPRESSION_EVENT: 'pc_rec_act_s_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_rec_act_s_bnr_clk',
      IMPRESSION_EVENT: 'mo_rec_act_s_bnr_imp',
    },
  },
  RECOMMEND_ACTIVITY_A: {
    PC: {
      CLICK_EVENT: 'pc_rec_act_a_bnr_clk',
      IMPRESSION_EVENT: 'pc_rec_act_a_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_rec_act_a_bnr_clk',
      IMPRESSION_EVENT: 'mo_rec_act_a_bnr_imp',
    },
  },
  WEEKLY_POPULAR: {
    PC: {
      CLICK_EVENT: 'pc_wkly_pop_act_clk',
      IMPRESSION_EVENT: 'pc_wkly_pop_act_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_wkly_pop_act_clk',
      IMPRESSION_EVENT: 'mo_wkly_pop_act_imp',
    },
  },
  RECRUIT: {
    PC: {
      CLICK_EVENT: 'pc_rec_bnr_clk',
      IMPRESSION_EVENT: 'pc_rec_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_rec_bnr_clk',
      IMPRESSION_EVENT: 'mo_rec_bnr_imp',
    },
  },
  RIGHT_SIDEBAR: {
    PC: {
      CLICK_EVENT: 'pc_rgt_side_all_pg_clk',
      IMPRESSION_EVENT: 'pc_rgt_side_all_pg_imp',
    },
  },
  HORIZONTAL: {
    PC: {
      CLICK_EVENT: 'pc_horz_ad_clk',
      IMPRESSION_EVENT: 'pc_horz_ad_imp',
    },
  },
  RECRUIT_QNA_BOTTOM: {
    PC: {
      CLICK_EVENT: 'pc_rec_qna_btm_clk',
      IMPRESSION_EVENT: 'pc_rec_qna_btm_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_rec_qna_btm_clk',
      IMPRESSION_EVENT: 'mo_rec_qna_btm_imp',
    },
    APP: {
      CLICK_EVENT: 'app_rec_qna_btm_clk',
      IMPRESSION_EVENT: 'app_rec_qna_btm_imp',
    },
  },
  COVER_LETTER_SEARCH: {
    PC: {
      CLICK_EVENT: 'pc_cvlt_srch_bnr_clk',
      IMPRESSION_EVENT: 'pc_cvlt_srch_bnr_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_cvlt_srch_bnr_clk',
      IMPRESSION_EVENT: 'mo_cvlt_srch_bnr_imp',
    },
    APP: {
      CLICK_EVENT: 'app_cvlt_srch_bnr_clk',
      IMPRESSION_EVENT: 'app_cvlt_srch_bnr_imp',
    },
  },
  LIVE_ACTIVITY: {
    PC: {
      CLICK_EVENT: 'pc_live_act_clk',
      IMPRESSION_EVENT: 'pc_live_act_imp',
    },
    MOBILE: {
      CLICK_EVENT: 'mo_live_act_clk',
      IMPRESSION_EVENT: 'mo_live_act_imp',
    },
  },
}

type TActivityGtmEventType = {
  PC: {
    IMPRESSION_EVENT: string
    CLICK_EVENT: string
  }
  MOBILE: {
    IMPRESSION_EVENT: string
    CLICK_EVENT: string
  }
}

type EducationGtmEventType = TActivityGtmEventType & {
  APP: {
    IMPRESSION_EVENT: string
    CLICK_EVENT: string
  }
}

export type TActivityGtmEvent = {
  [K in ACTIVITY_NOTICE_AD_CODE_KEY]: K extends 'EDUCATION_NOTICE'
    ? EducationGtmEventType
    : TActivityGtmEventType
}

export const ACTIVITY_GTM_EVENT: TActivityGtmEvent = {
  INTERN_NOTICE: {
    PC: {
      IMPRESSION_EVENT: 'pc_int_ntc_bnr_imp',
      CLICK_EVENT: 'pc_int_ntc_bnr_clk',
    },
    MOBILE: {
      IMPRESSION_EVENT: 'mo_int_ntc_bnr_imp',
      CLICK_EVENT: 'mo_int_ntc_bnr_clk',
    },
  },
  RECRUIT_NOTICE: {
    PC: {
      IMPRESSION_EVENT: 'pc_rec_ntc_bnr_imp',
      CLICK_EVENT: 'pc_rec_ntc_bnr_clk',
    },
    MOBILE: {
      IMPRESSION_EVENT: 'mo_rec_ntc_bnr_imp',
      CLICK_EVENT: 'mo_rec_ntc_bnr_clk',
    },
  },
  ACTIVITY_NOTICE: {
    PC: {
      IMPRESSION_EVENT: 'pc_act_ntc_bnr_imp',
      CLICK_EVENT: 'pc_act_ntc_bnr_clk',
    },
    MOBILE: {
      IMPRESSION_EVENT: 'mo_act_ntc_bnr_imp',
      CLICK_EVENT: 'mo_act_ntc_bnr_clk',
    },
  },
  CONTEST_NOTICE: {
    PC: {
      IMPRESSION_EVENT: 'pc_ctst_ntc_bnr_imp',
      CLICK_EVENT: 'pc_ctst_ntc_bnr_clk',
    },
    MOBILE: {
      IMPRESSION_EVENT: 'mo_ctst_ntc_bnr_imp',
      CLICK_EVENT: 'mo_ctst_ntc_bnr_clk',
    },
  },
  EDUCATION_NOTICE: {
    PC: {
      IMPRESSION_EVENT: 'pc_edu_ntc_bnr_imp',
      CLICK_EVENT: 'pc_edu_ntc_bnr_clk',
    },
    MOBILE: {
      IMPRESSION_EVENT: 'mo_edu_ntc_bnr_imp',
      CLICK_EVENT: 'mo_edu_ntc_bnr_clk',
    },
    APP: {
      IMPRESSION_EVENT: 'app_edu_ntc_bnr_imp',
      CLICK_EVENT: 'app_edu_ntc_bnr_clk',
    },
  },
}
