import { FC } from 'react'
import styled from 'styled-components'
import { UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment } from 'generated/graphql'
import SecondInfoText from 'components/ActivityListCardItem/SecondInfoText'

interface IFProps {
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
  onClick?: () => void
}

const SBannerHoverCardDesktop: FC<IFProps> = ({ ad, onClick }) => {
  const secondInfos = {
    recruitCloseAt: ad?.activity?.recruitCloseAt,
    recruitType: ad?.activity?.recruitType,
    replyCount: ad?.activity?.replyCount,
    viewCount: ad?.activity?.viewCount,
  }

  return (
    <StyledWrapper>
      <a onClick={onClick}>
        <div className="info-title">{ad?.activity?.title || ''}</div>
      </a>
      <div className="second-info-text">
        <div className="organization-name">
          {ad?.activity?.organizationName || '-'}
        </div>
        <SecondInfoText activityInfos={secondInfos} />
      </div>
    </StyledWrapper>
  )
}

export default SBannerHoverCardDesktop

const StyledWrapper = styled.div`
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 67px;
  width: 100%;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  .info-title {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: bold;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .second-info-text {
    display: flex;
    justify-content: space-between;
    .organization-name {
      font-size: 12px;
      font-weight: 500;
    }
  }
`
