import { FC } from 'react'
import { ActivityScrap_CurrentUserFragment } from 'generated/graphql'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import shuffleList from '@app.feature/ad/modules/shuffleList'
import RecommendBannerListMobile from './RecommendBannerList.mobile'
import RecommendBannerCardLoadingMobile from './card/loading/RecommendBannerCardLoading.mobile'

interface IFProps {
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const RecommendBannerListStatusMobile: FC<IFProps> = ({ currentUser }) => {
  const {
    ads: sBanner,
    loading: SBannerLoading,
    error: sBannerError,
  } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.RECOMMEND_ACTIVITY_S.MOBILE!,
    restVariables: {
      isActivityCard: true,
    },
  })

  const {
    ads: aBanner,
    loading: aBannerLoading,
    error: aBannerError,
  } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.RECOMMEND_ACTIVITY_A.MOBILE!,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (
    (SBannerLoading && aBannerLoading) ||
    (sBannerError && aBannerError) ||
    (!sBanner?.length && !aBanner?.length)
  ) {
    return <RecommendBannerCardLoadingMobile />
  }

  const recommendActivities = [
    ...(sBanner || []),
    ...shuffleList(aBanner || []),
  ]

  return (
    <RecommendBannerListMobile
      recommendActivities={recommendActivities}
      currentUser={currentUser}
    />
  )
}

export default RecommendBannerListStatusMobile
