import { FC } from 'react'
import styled from 'styled-components'
import {
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ActivityListCardItem_CurrentUserFragment,
} from 'generated/graphql'
import PopularRecruitItemDesktop from './PopularRecruitItem.desktop'

interface IFPopularRecruitDesktopProps {
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityListCardItem_CurrentUserFragment
}

const PopularRecruitDesktop: FC<IFPopularRecruitDesktopProps> = ({
  ads,
  currentUser,
}) => {
  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      <ul className="activity-list">
        {ads.map((ad) => {
          return (
            <PopularRecruitItemDesktop
              key={ad.activity.id}
              ad={ad}
              currentUser={currentUser}
            />
          )
        })}
      </ul>
    </StyledWrapper>
  )
}

export default PopularRecruitDesktop

const StyledWrapper = styled.div`
  .title-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .activity-list {
    display: flex;
    gap: 28px;
  }
`
