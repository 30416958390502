import { memo } from 'react'
import Skeleton from '@app.components/Skeleton'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import useGetAdsByPlacementCode from '@app.feature/ad/hooks/useGetAdsByPlacementCode'
import MainBannerListDesktop from './MainBannerList.desktop'

const MainBannerStatusDesktop = () => {
  const { ads, loading, error } = useGetAdsByPlacementCode({
    code: AD_CODE_ID.MAIN.PC,
    isShuffled: true,
  })

  if (loading || error || !ads?.length) {
    return <Skeleton width={345} height={500} />
  } else {
    return <MainBannerListDesktop ads={ads} />
  }
}

export default memo(MainBannerStatusDesktop)
