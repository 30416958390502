import { FC, useRef } from 'react'
import styled from 'styled-components'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import {
  ActivityScrap_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useSwiperAdViewportTracking from '@app.feature/ad/hooks/useSwiperAdViewportTracking'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import RecommendBannerCardMobile from './card/RecommendBannerCard.mobile'

interface IFProps {
  recommendActivities: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityScrap_CurrentUserFragment | null
}

const RecommendBannerListMobile: FC<IFProps> = ({
  recommendActivities,
  currentUser,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null)

  const trackImpression = (index: number) => {
    const ad = recommendActivities[index]
    if (ad?.ad) {
      const eventName =
        (ad.ad.campaign?.unifiedAdPlacement.code ===
        'recommend-activity-s-banner'
          ? GTM_EVENT.RECOMMEND_ACTIVITY_S.MOBILE?.IMPRESSION_EVENT
          : GTM_EVENT.RECOMMEND_ACTIVITY_A.MOBILE?.IMPRESSION_EVENT) || ''

      pushGTMCampaignId({
        eventName,
        campaignId: ad.ad.campaign?.id || '',
      })
    }
  }

  const { containerRef, isVisible } = useSwiperAdViewportTracking({
    swiperRef: swiperRef,
    onVisible: trackImpression,
  })

  const handleRealIndexChange = (swiper: SwiperClass) => {
    if (isVisible) {
      trackImpression(swiper.realIndex)
    }
  }

  return (
    <StyledWrapper ref={containerRef}>
      <Swiper
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
        loop
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        onRealIndexChange={handleRealIndexChange}
      >
        {recommendActivities?.map((ad, index) => (
          <SwiperSlide
            key={`recommend-activity-item-mobile-${ad?.activity.id}-${index}`}
          >
            <RecommendBannerCardMobile
              currentUser={currentUser}
              ad={ad}
              totalSlide={recommendActivities.length}
              activeSlide={index + 1}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledWrapper>
  )
}

export default RecommendBannerListMobile

const StyledWrapper = styled.div`
  position: relative;
`
