import { FC } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import SecondInfoText from 'components/ActivityListCardItem/SecondInfoText'
import Link from 'components/Link'

interface IFProps {
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
}

const RecommendBannerCardInfoMobile: FC<IFProps> = ({ ad }) => {
  const router = useRouter()

  const secondInfos = {
    recruitCloseAt: ad?.activity?.recruitCloseAt,
    recruitType: ad?.activity?.recruitType,
    replyCount: ad?.activity?.replyCount,
    viewCount: ad?.activity?.viewCount,
  }

  const linkProps = {
    href: `/activity/[id]`,
    as: `/activity/${ad.activity?.id}`,
    naked: true,
    ...(ad.ad?.campaign?.landingURL && {
      onClick: () => {
        const eventName =
          (ad.ad?.campaign?.unifiedAdPlacement.code ===
          'recommend-activity-s-banner'
            ? GTM_EVENT.RECOMMEND_ACTIVITY_S.MOBILE?.CLICK_EVENT
            : GTM_EVENT.RECOMMEND_ACTIVITY_A.MOBILE?.CLICK_EVENT) || ''

        pushGTMCampaignId({
          eventName,
          campaignId: ad.ad?.campaign?.id || '',
        })

        window.open(
          ad.ad?.campaign?.landingURL,
          '_blank',
          'noopener, noreferrer',
        )
      },
    }),
  }

  const handleClickOrganization = () => {
    if (ad.activity?.manager?.channel) {
      router.push(`/channel/${ad.activity.manager.channel.url}`)
    }
  }

  return (
    <StyledWrapper hasChannel={!!ad.activity?.manager?.channel}>
      <div className="second-info">
        <div className="second-info-wrapper">
          <Link {...linkProps}>
            <p className="second-info-title">{ad.activity.title}</p>
          </Link>
          <div className="orgname-second-info-wrapper">
            <p className="organization-name" onClick={handleClickOrganization}>
              {ad.activity?.organizationName || '-'}
            </p>
            <SecondInfoText activityInfos={secondInfos} />
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default RecommendBannerCardInfoMobile

type TStyleProps = {
  hasChannel: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  .second-info-wrapper {
    top: 0;
    left: 0;
    background-color: white;
    padding: 15px 20px 16px 20px;
  }

  .orgname-second-info-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .org-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.66;
    letter-spacing: -0.6px;
  }

  .second-info-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .organization-name {
    cursor: ${({ hasChannel }) => hasChannel && 'pointer'};
    font-size: 12px;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-rendering: auto;
  }
`
