import { FC } from 'react'
import styled from 'styled-components'
import {
  ActivityListCardItem_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useAdImpressionTracker from '@app.feature/ad/hooks/useAdImpressionTracker'
import getActivityListFromAdsImageUrl from '@app.feature/ad/modules/getActivityListFromAdsImageUrl'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListCardItem from 'components/ActivityListCardItem'

interface IFProps {
  ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment
  currentUser?: ActivityListCardItem_CurrentUserFragment
}

const PopularRecruitItemMobile: FC<IFProps> = ({ ad, currentUser }) => {
  const isAd = !!ad.ad

  useAdImpressionTracker({
    eventName: GTM_EVENT.RECRUIT.MOBILE?.IMPRESSION_EVENT || '',
    campaignId: ad.ad?.campaign?.id || '',
  })

  const handlePushGtmEvent = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.RECRUIT.MOBILE?.CLICK_EVENT || '',
      campaignId: ad.ad?.campaign?.id || '',
    })
  }

  const handleOnClickOutLink = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    handlePushGtmEvent(ad)
    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad?.campaign?.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  return (
    <StyledWrapper
      key={ad.activity.id}
      className="card-item"
      {...(!!ad.ad && {
        'aria-label': 'Advertisement',
        role: 'advertisement',
      })}
    >
      <ActivityListCardItem
        activity={ad.activity}
        cardTitle={ad.activity?.title}
        currentUser={currentUser}
        managerBadgeOn={false}
        secondinfoTextProps={{
          closeDateHighLightClassName: 'close-date-highlight',
        }}
        cardImageProps={{
          width: 155,
          src: getActivityListFromAdsImageUrl({
            adImage: ad.ad?.image?.url,
            activity: ad.activity,
          }),
          alt: ad.activity?.title || '',
        }}
        {...(isAd && {
          linkProps: {
            onClick: () => handleOnClickOutLink(ad),
          },
          onClick: () => handlePushGtmEvent(ad),
        })}
      />
    </StyledWrapper>
  )
}

export default PopularRecruitItemMobile

const StyledWrapper = styled.li`
  width: calc(50% - 5px);
  flex-shrink: 0;
  .close-date-highlight {
    color: #01a0ff;
  }
`
