import { FC, Fragment, useMemo } from 'react'
import gql from 'graphql-tag'
import pick from 'lodash/pick'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import {
  ActivityListCardItem_ActivityFragment,
  ActivityListCardItem_CurrentUserFragment,
  ActivityListCardItem_SecondInfoText_ActivityFragment,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import useIsMobile from '@app.modules/hooks/useIsMobile'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import { getWebviewHref } from '@app.modules/util'
import { mobileBreakPoint } from 'app.styled/media'
import Link, { LinkProps } from 'components/Link'
import { ActivityTypeID } from 'constants/enums'
import { imageLoader, optimizeImageUrl } from 'utils/optimizeImageUrl'
import ScrapBoxButton from './ScrapBoxButton'
import SecondInfoText, { SecondInfoTextProps } from './SecondInfoText'
import { getLimitNumText } from './module/getLimitNumText'

gql`
  fragment ActivityListCardItem_currentUser on User {
    ...ActivityScrap_currentUser
  }
  fragment ActivityListCardItem_activity on Activity {
    id
    title
    type
    thumbnailImage {
      id
      url
    }
    logoImage {
      id
      url
    }
    manager @include(if: $withManager) {
      id
      isStaff
      channel {
        id
        url
      }
    }
    chatRoom {
      id
    }
    managerReplies @include(if: $withManager) {
      id
    }
    organizationName
    scrapCount
    isScrapped
    ...ActivityListCardItem_SecondInfoText_activity
    ...ActivityScrap_activity
  }
`

interface IFCardImgProps {
  width?: number | string
  src: string
  alt: string
}

interface IFActivityListCardItem {
  activity?: ActivityListCardItem_ActivityFragment
  currentUser?: ActivityListCardItem_CurrentUserFragment
  isLoading?: boolean
  className?: string
  cardTitle?: string | null
  cardImageProps?: IFCardImgProps
  linkProps?: Partial<LinkProps>
  managerBadgeOn?: boolean
  secondinfoTextProps?: Partial<SecondInfoTextProps>
  isRecommend?: boolean
  onClick?: () => void
}

const ActivityListCardItem: FC<IFActivityListCardItem> = ({
  activity,
  currentUser,
  isLoading,
  className,
  cardImageProps,
  linkProps,
  managerBadgeOn = true,
  secondinfoTextProps,
  cardTitle,
  isRecommend,
  onClick,
}) => {
  const router = useRouter()
  const isWebview = useIsWebview()
  const isMobile = useIsMobile()
  const isRecruit = activity?.type === ActivityTypeID.RECRUIT

  const ActivityItemLinkProps = {
    href: '/activity/[id]',
    as: `/activity/${activity?.id}`,
    naked: true,
    onClick: () => onClick && onClick(),
  }

  const secondInfos = pick(activity, [
    'recruitCloseAt',
    'recruitType',
    'replyCount',
    'viewCount',
  ]) as ActivityListCardItem_SecondInfoText_ActivityFragment

  const imageUrl =
    cardImageProps?.src ||
    (isRecruit ? activity?.logoImage?.url : activity?.thumbnailImage?.url) ||
    ''

  const imageWidth = useMemo(() => {
    if (cardImageProps?.width) {
      return cardImageProps?.width as number
    } else {
      return isMobile ? 300 : 250
    }
  }, [cardImageProps?.width, isMobile])

  const handleClickOrganization = () => {
    if (activity?.manager?.channel) {
      router.push(
        getWebviewHref(`/channel/${activity.manager.channel.url}`, isWebview),
      )
    }
  }

  return (
    <StyledWrapper className={className}>
      <div className="image-wrapper">
        {isLoading && <Skeleton className="skeleton-rect" />}
        {!isLoading && (
          <Fragment>
            <Link
              className="image-link"
              {...ActivityItemLinkProps}
              {...linkProps}
            >
              <Image
                loader={imageLoader}
                key={`activity-image-${activity?.id}`}
                alt={cardImageProps?.alt || activity?.title || ''}
                className="activity-image"
                width={imageWidth}
                height={imageWidth}
                src={optimizeImageUrl({
                  url: imageUrl,
                  width: imageWidth,
                  height: 'auto',
                })}
                objectFit="cover"
              />
            </Link>
            <ScrapBoxButton activity={activity} currentUser={currentUser} />
            {managerBadgeOn && !activity?.manager?.isStaff && (
              <div className="badge-wrapper">
                <div className="manager-exists">
                  {activity?.managerReplies?.length ? (
                    <Fragment>
                      <span>담당자 답변 </span>
                      <span className="reply-count">
                        {getLimitNumText({
                          number: activity?.managerReplies?.length,
                          limit: 99,
                        })}
                      </span>
                    </Fragment>
                  ) : (
                    '담당자 답변받기'
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="card-content">
        {isLoading && (
          <Fragment>
            <Skeleton height={36} width="100%" className="title-skeleton" />
            <Skeleton height={12} width="100%" />
            <div className="skeleton-wrapper">
              <Skeleton height={12} width="30%" />
              <Skeleton height={12} width="30%" />
              <Skeleton height={12} width="30%" />
            </div>
          </Fragment>
        )}
        {!isLoading && (
          <Fragment>
            <Link {...ActivityItemLinkProps}>
              <div className="flex-box">
                {isRecommend && <div className="recommend-tag">추천</div>}
                <h5 className="activity-title">
                  {cardTitle || activity?.title || '-'}
                </h5>
              </div>
            </Link>
            <div>
              <p
                className="organization-name"
                style={{
                  cursor: activity?.manager?.channel ? 'pointer' : 'default',
                }}
                onClick={handleClickOrganization}
              >
                {activity?.organizationName || '-'}
              </p>
              <SecondInfoText
                activityInfos={secondInfos}
                {...secondinfoTextProps}
              />
            </div>
          </Fragment>
        )}
      </div>
    </StyledWrapper>
  )
}

export default ActivityListCardItem

const StyledWrapper = styled.div`
  width: 100%;
  .image-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    .skeleton-rect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    .image-link {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      width: 100%;
      height: 100%;

      overflow: hidden;

      .activity-image {
        width: 100% !important;
        height: auto !important;
        min-height: auto !important;
      }
    }
    .badge-wrapper {
      display: flex;
      position: absolute;
      left: 8px;
      bottom: 8px;
      border-radius: 3px;
      padding: 7px;
      background-color: rgba(0, 0, 0, 0.7);
      .manager-exists {
        color: #fff;
        font-size: 10px;
        .reply-count {
          color: #ffdf11;
        }
      }
    }
  }
  .card-content {
    display: grid;
    gap: 5px;
    padding: 10px 0 0 0;

    .title-skeleton {
      margin: 10px 0px;
    }
    .skeleton-wrapper {
      display: flex;
      gap: 10px;
    }
    .flex-box {
      display: flex;
      .recommend-tag {
        height: 100%;
        display: flex;
        padding: 2px;
        font-size: 11px;
        color: #ff0066;
        border: 1px solid #ff0066;
        border-radius: 3px;
        margin-right: 5px;
        flex-shrink: 0;
      }
      .activity-title {
        flex: 1;
        display: -webkit-box;
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
        ${mobileBreakPoint} {
          height: 38px;
          font-size: 14px;
        }
      }
    }
    .organization-name {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
`
