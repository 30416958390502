import { FC, memo } from 'react'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'
import { PC_AD_CODE } from '@app.feature/ad/constants/adCode'
import useGetAdByPlacementCodeAndCurrentTime from '@app.feature/ad/hooks/useGetAdByPlacementCodeAndCurrentTime'
import RightSideBarBannerDesktop from './RightSideBarBanner.desktop'

interface IFProps {
  className?: string
}

const RightSideBarBannerStatusDesktop: FC<IFProps> = ({ className }) => {
  const { ad, loading, error } = useGetAdByPlacementCodeAndCurrentTime({
    code: PC_AD_CODE.RIGHT_SIDEBAR,
  })

  if (loading) {
    return <SkeletonWrapper className={className} />
  }

  if (error || !ad) {
    return null
  }

  return <RightSideBarBannerDesktop className={className} ad={ad} />
}

export default memo(RightSideBarBannerStatusDesktop)

const SkeletonWrapper = styled(Skeleton)`
  position: absolute;
  top: 614px;
  left: calc(100% + 20px);
  width: 160px;
  height: 600px;
`
