// Internal config
// 환경변수 관련된 내용 .env 파일 참고
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

// NODE ENV
export const NODE_ENV =
  publicRuntimeConfig.NODE_ENV ||
  process.env.NEXT_PUBLIC_NODE_ENV ||
  'development'

// LINKAREER ENV
export const LINKAREER_API_BASE_URL =
  process.env.NEXT_PUBLIC_LINKAREER_API_BASE_URL ||
  'https://gateway-api.lnkr.cc'
export const LINKAREER_CLOUDINARY_NAME =
  process.env.NEXT_PUBLIC_LINKAREER_CLOUDINARY_NAME || ''
export const LINKAREER_DOMAIN =
  process.env.NEXT_PUBLIC_LINKAREER_DOMAIN || 'localhost:7000'
export const LINKAREER_LEGACY_BASE_URL =
  process.env.NEXT_PUBLIC_LINKAREER_LEGACY_BASE_URL || 'http://localhost:8000'
export const XEN_HOSTNAME =
  process.env.NEXT_PUBLIC_XEN_HOSTNAME || 'localhost:5050'
export const LINKAREER_BIZ_BASE_URL =
  process.env.NEXT_PUBLIC_LINKAREER_BIZ_BASE_URL || 'localhost:8090'
export const LINKAREER_BASE_URL =
  process.env.NEXT_PUBLIC_LINKAREER_BASE_URL || ''
export const LINKAREER_COMMUNITY_API_BASE_URL =
  process.env.NEXT_PUBLIC_COMMUNITY_API_BASE_URL ||
  'https://api.lnkr.cc/community'
export const LINKAREER_CHAT_URL =
  process.env.NEXT_PUBLIC_LINKAREER_CHAT_URL || ''
export const LINKAREER_ACADEMY_BASE_URL =
  process.env.NEXT_PUBLIC_ACADEMY_BASE_URL || 'https://cbt.lnkr.cc'
export const LINKAREER_ACADEMY_COMMUNITY_BASE_URL =
  process.env.NEXT_PUBLIC_ACADEMY_COMMUNITY_BASE_URL ||
  'https://cbt-community.lnkr.cc'

export const GOOGLE_GTM_ID =
  process.env.NEXT_PUBLIC_GOOGLE_GTM_ID || 'GTM-KSFWHLX'

// SEO ENV
export const GOOGLE_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION || ''
export const NAVER_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_NAVER_SITE_VERIFICATION || ''

// ETC
export const PROTOCOL = NODE_ENV === 'production' ? 'https' : 'http'

export const TIMEZONE = 'Asia/Seoul'

export const COPY_SUFFIX =
  '대학생 대외활동 공모전 채용 사이트 링커리어 https://linkareer.com/'
