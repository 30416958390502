import { FC } from 'react'
import { ActivityListCardItem_CurrentUserFragment } from 'generated/graphql'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import PopularActivityDesktop from '@app.feature/ad/desktop/PopularActivity/PopularActivity.desktop'
import useGetActivityListFromAdsByPlacementCode from '@app.feature/ad/hooks/useGetActivityListFromAdsByPlacementCode'
import PopularActivityLoadingDesktop from './loading/PopularActivityLoading.desktop'

interface IFProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  isScreenQueryLoading: boolean
}

const PopularActivityStatusDesktop: FC<IFProps> = ({
  currentUser,
  isScreenQueryLoading,
}) => {
  const { ads, loading, error } = useGetActivityListFromAdsByPlacementCode({
    adPlacementCode: AD_CODE_ID.WEEKLY_POPULAR.PC,
    restVariables: {
      isActivityCard: true,
    },
  })

  if (isScreenQueryLoading || loading || error) {
    return <PopularActivityLoadingDesktop />
  }

  if (!ads?.length) {
    return null
  }

  return <PopularActivityDesktop ads={ads} currentUser={currentUser} />
}

export default PopularActivityStatusDesktop
