import { createContext, useContext } from 'react'
import { TCompareVersion, verifyAppVersion } from 'utils/verifyAppVersion'
import {
  XEN_HOSTNAME,
  PROTOCOL,
  LINKAREER_DOMAIN,
  LINKAREER_API_BASE_URL,
  LINKAREER_ACADEMY_COMMUNITY_BASE_URL,
} from './utils/config'

// 도메인 관련 context 정의

export interface Domains {
  xen: string
  protocol: string
  linkareer: string
  linkareerAPIBaseURL: string
  academyCommunityBaseUrl: string
}

// MEMO with-config.js 에서 config 값과 연동되어있음
export const DomainsContext = createContext({
  xen: XEN_HOSTNAME,
  protocol: PROTOCOL,
  linkareer: LINKAREER_DOMAIN,
  linkareerAPIBaseURL: LINKAREER_API_BASE_URL,
  academyCommunityBaseUrl: LINKAREER_ACADEMY_COMMUNITY_BASE_URL,
})

export function useDomain(): Domains {
  const domains = useContext(DomainsContext)
  return domains
}

// UA 관련 context 정의

interface UAProps {
  browserName: string
  uaString: string
  isKakaoScrap?: boolean
  isMobile?: boolean
}

export const UAContext = createContext({
  browserName: '',
  uaString: '',
  isMobile: false,
})

export const uaIsKakaoScrap = (uaString: string): boolean => {
  return uaString.includes('kakaotalk-scrap/1.0;')
}

export function useUA(): UAProps {
  const { browserName, uaString, isMobile } = useContext(UAContext)

  // https://devtalk.kakao.com/t/topic/128151/6
  const isKakaoScrap = uaIsKakaoScrap(uaString)

  return {
    browserName,
    uaString,
    isKakaoScrap,
    isMobile,
  }
}

// Webview 관련 context 정의

type TAppVersion = `${'i' | 'a'}${string}.${string}.${string}`

type TVerifyAppVersionRequirement = (version: TCompareVersion) => boolean
type TUseWebviewHookParams = {
  appVersion: TAppVersion
}

interface IFWebviewContext {
  isWebview: boolean
  fluterInappwebview: any
  appVersion: TAppVersion
  verifyAppVersionRequirement: TVerifyAppVersionRequirement
}

export const WebviewContext = createContext({
  isWebview: false,
  fluterInappwebview: undefined,
  appVersion: '',
})

export function useWebview(params: TUseWebviewHookParams): IFWebviewContext {
  const { appVersion } = params
  const { isWebview, fluterInappwebview } = useContext(WebviewContext)

  const verifyAppVersionRequirement = (compareVersion: TCompareVersion) => {
    if (!appVersion) {
      return true
    }

    return verifyAppVersion(appVersion as TAppVersion, compareVersion)
  }

  return {
    isWebview,
    fluterInappwebview,
    appVersion: appVersion as TAppVersion,
    verifyAppVersionRequirement,
  }
}
