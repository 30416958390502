import {
  useGqlViewerEducationLazyQuery,
  useGqlViewerJobCategoriesLazyQuery,
  useGqlViewerPreferActivityTypesLazyQuery,
  useGqlViewerPreferOrganizationIdsLazyQuery,
  UserEducation,
} from 'generated/graphql'

export const useGetPreviousUserInfo = () => {
  const [getViewerPreferActivityTypes] =
    useGqlViewerPreferActivityTypesLazyQuery()
  const [getViewerPreferOrganizationIds] =
    useGqlViewerPreferOrganizationIdsLazyQuery()
  const [getViewerJobCategoriesData] = useGqlViewerJobCategoriesLazyQuery()
  const [getViewerEducationData] = useGqlViewerEducationLazyQuery()

  const getViewerData = async () => {
    try {
      const [
        preferActivityTypes,
        preferOrganizationIds,
        viewerJobCategoriesData,
        viewerEducationData,
      ] = await Promise.all([
        getViewerPreferActivityTypes(),
        getViewerPreferOrganizationIds(),
        getViewerJobCategoriesData(),
        getViewerEducationData(),
      ])

      return {
        preferActivityTypes:
          preferActivityTypes.data?.viewerPreferActivityTypes?.map(
            (item) => item?.id,
          ) || [],
        preferOrganizationIds:
          preferOrganizationIds.data?.viewerPreferOrganizationIds?.map(
            (item) => item?.id,
          ) || undefined,
        preferJobCategoryIds:
          viewerJobCategoriesData.data?.viewerJobCategories?.map(
            (item) => item?.id,
          ) || [],
        schoolType:
          viewerEducationData.data?.viewerEducation?.educationType ||
          ('' as UserEducation),
        school: {
          id: viewerEducationData.data?.viewerEducation?.school?.id || '',
          name: viewerEducationData.data?.viewerEducation?.school?.name || '',
        },
        schoolMajor: {
          id: viewerEducationData.data?.viewerEducation?.major?.id || '',
          name: viewerEducationData.data?.viewerEducation?.major?.name || '',
        },
      }
    } catch (err) {
      return {
        preferActivityTypes: [],
        preferOrganizationIds: undefined,
        preferJobCategoryIds: [],
        schoolType: '' as UserEducation,
        school: {
          id: '',
          name: '',
        },
      }
    }
  }
  return { getViewerData }
}
