import styled from 'styled-components'
import ActivityLoadingItem from '@app.feature/home/components/ActivityLoadingItem/ActivityLoadingItem'

const POPULAR_ACTIVITY_PER_VIEW = 2

const PopularActivityLoadingMobile = () => {
  return (
    <StyledWrapper>
      <h2 className="title-text">금주의 인기공고</h2>
      <div className="loading-wrapper">
        <ActivityLoadingItem
          time={POPULAR_ACTIVITY_PER_VIEW}
          className="loading-item"
        />
      </div>
    </StyledWrapper>
  )
}

export default PopularActivityLoadingMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .title-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .loading-wrapper {
    display: flex;
    gap: 10px;
    padding-right: 20px;
    .loading-item {
      width: 100%;
    }
  }
`
