import { FC, useEffect } from 'react'
import styled from 'styled-components'
import {
  ActivityListItem_CurrentUserFragment,
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
} from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import ActivityListItem from 'components/ActivityListItem'
import LiveActivityListTitleDesktop from './ActivityListTitle.desktop'

interface IFProps {
  ads: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  currentUser?: ActivityListItem_CurrentUserFragment | null
}

const LiveActivityListDesktop: FC<IFProps> = ({ currentUser, ads }) => {
  const handleOnClickOutLink = (
    ad: UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  ) => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.LIVE_ACTIVITY.PC.CLICK_EVENT,
      campaignId: ad.ad?.campaign?.id || '',
    })
    if (ad.ad?.campaign?.landingURL) {
      window.open(ad.ad?.campaign?.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  useEffect(() => {
    ads?.forEach((ad) => {
      if (ad.ad) {
        pushGTMCampaignId({
          eventName: GTM_EVENT.LIVE_ACTIVITY.PC.IMPRESSION_EVENT || '',
          campaignId: ad.ad?.campaign?.id || '',
        })
      }
    })
  }, [])

  return (
    <StyledWrapper>
      <LiveActivityListTitleDesktop />
      {ads?.map((ad) => {
        const isAd = !!ad.ad

        return (
          <article
            key={ad.activity.id}
            className="activity-article"
            data-is-ad={isAd}
            {...(isAd && {
              'aria-label': 'Advertisement',
              role: 'advertisement',
            })}
          >
            <ActivityListItem
              activity={ad.activity}
              currentUser={currentUser}
              showActivityType
              showHotIcon={isAd}
              className="activity-item"
              {...(isAd && {
                linkProps: {
                  onClick: () => handleOnClickOutLink(ad),
                },
              })}
            />
          </article>
        )
      })}
    </StyledWrapper>
  )
}

export default LiveActivityListDesktop

const StyledWrapper = styled.div`
  .activity-article {
    border-top: 1px solid #eee;
    :last-child {
      border-bottom: 1px solid #eee;
    }
    .activity-item {
      padding-left: 20px;
      max-height: 93px;
    }
    &[data-is-ad='true'] {
      border-top: 1px solid #d6f0ff;
      .activity-item {
        background-color: #f2faff;
      }
    }
  }
`
