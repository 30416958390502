import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { SwiperClass } from 'swiper/react'
import { AD_IMPRESSION_THRESHOLD } from '../constants/constants'

interface useAdCarouselViewportTrackingParams {
  onVisible?: (index: number) => void
  swiperRef: MutableRefObject<SwiperClass | null>
}

type TUseAdCarouselViewportTrackingFn = (
  params: useAdCarouselViewportTrackingParams,
) => {
  containerRef: MutableRefObject<HTMLDivElement | null>
  isVisible: boolean
}

/**
 * 스와이프 형태의 광고 영역이 90% 이상 viewport에 노출되었을 때 impression을 추적하는 커스텀 훅
 *
 *
 * @description
 * 광고 영역이 90% 이상 노출되었을 때
 * 1) Swiper 라이브러리의 현재 활성화된 광고 슬라이드의 index를 추적
 * 2) onVisible 함수를 발생시키고 index를 파라미터로 전송합니다.
 *
 * @param {Object}
 * @param {Function} params.onVisible - 광고가 viewport에 노출되었을 때 호출되는 콜백 함수. 현재 활성화된 슬라이드의 index를 인자로 받습니다.
 * @param {MutableRefObject<SwiperClass | null>} params.swiperRef - Swiper 인스턴스를 참조하는 ref 객체
 *
 * @returns {Object}
 * @returns {MutableRefObject<HTMLDivElement | null>} containerRef - 광고 컨테이너 요소를 참조하는 ref 객체
 * @returns {boolean} isVisible - 광고가 viewport에 노출되었는지 여부
 */
const useSwiperAdViewportTracking: TUseAdCarouselViewportTrackingFn = ({
  onVisible,
  swiperRef,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isInViewport =
            entry.isIntersecting &&
            entry.intersectionRatio >= AD_IMPRESSION_THRESHOLD

          setIsVisible(isInViewport)

          if (isInViewport && swiperRef.current) {
            onVisible && onVisible(swiperRef.current.realIndex)
          }
        })
      },
      {
        threshold: [AD_IMPRESSION_THRESHOLD],
        root: null,
        rootMargin: '0px',
      },
    )

    observer.observe(containerRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return {
    containerRef,
    isVisible,
  }
}

export default useSwiperAdViewportTracking
