import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { UseGetAdByPlacementCodeAndCurrentTime_AdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useAdImpressionTracker from '@app.feature/ad/hooks/useAdImpressionTracker'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import { imageLoader, optimizeImageUrl } from 'utils/optimizeImageUrl'

interface IFProps {
  ad: UseGetAdByPlacementCodeAndCurrentTime_AdFragment
  className?: string
}

const RIGHT_SIDEBAR_BANNER_PC_IMAGE_SIZE = {
  width: 160,
  height: 600,
}

const RightSideBarBannerDesktop: FC<IFProps> = ({ ad, className }) => {
  useAdImpressionTracker({
    eventName: GTM_EVENT.RIGHT_SIDEBAR.PC.IMPRESSION_EVENT,
    campaignId: ad.campaign?.id,
  })

  const handleClickOutLink = () => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.RIGHT_SIDEBAR.PC.CLICK_EVENT,
      campaignId: ad.campaign?.id || '',
    })
    if (ad.campaign?.landingURL) {
      window.open(ad.campaign.landingURL, '_blank', 'noopener, noreferrer')
    }
  }
  const activityUrl = ad.activity?.id ? `/activity/${ad.activity?.id}` : ''

  const alt = ad?.campaign?.campaignGroup?.name
    ? `${ad?.campaign?.campaignGroup?.name} 우측 사이드바 배너 이미지`
    : `우측 사이드바 배너 이미지`

  return (
    <StyledWrapper className={className} onClick={handleClickOutLink}>
      <Link href={activityUrl}>
        <a rel="nofollow sponsored">
          <Image
            className="right-sidebar-banner-image"
            key="right-sidebar-banner-image"
            alt={alt}
            width={RIGHT_SIDEBAR_BANNER_PC_IMAGE_SIZE.width}
            height={RIGHT_SIDEBAR_BANNER_PC_IMAGE_SIZE.height}
            loader={imageLoader}
            src={optimizeImageUrl({
              url: ad.image?.url,
              width: RIGHT_SIDEBAR_BANNER_PC_IMAGE_SIZE.width,
              height: RIGHT_SIDEBAR_BANNER_PC_IMAGE_SIZE.height,
            })}
          />
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default RightSideBarBannerDesktop

const StyledWrapper = styled.div`
  position: absolute;
  top: 614px;
  left: calc(100% + 20px);
  width: 160px;
  height: 600px;

  .right-sidebar-banner-image {
    border-radius: 5px;
  }
`
